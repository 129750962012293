<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      <div class="text-center">Palindrome</div>
    </div>
    <div class="mainpage">
      <div class="mb-2">
        How many times does it takes for a number to become a palindrome by adding the reverse number?
        Most numbers get there rather quickly but some take longer (this algorithm stops after 100 tries).
      </div>
      <div class="form-inline mb-2">
        <label class="form-label col-sm-4 col-md-2" for="x">Start with</label>
        <input type='number' id="start" name="start" ref="start" v-model="start" class="form-control col-sm-4 col-md-2">
        <div class="col-md-4 p-2">
          <input type="button" id="palindrome" name="palindrome" value="Palindrome counter" class="btn btn-primary mr-2" v-on:click="palindromeCounter">
        </div>
      </div>
      <div class="card">
        <div class="card-header">Palindrome sequence</div>
        <div class="card-text p-2">
          It takes {{n}} iterations to become a palindrome.<br>
          The sequence is: {{seq}}
        </div>
      </div>
      <p v-show="error" class="errormsg">{{errormsg}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PalindromeCounter',
  props: {
    msg: String
  },
  data: function () {
    return {
      title: "PalindromeCounter",
      start: 0,
      n: 0,
      seq: "",
      error: false,
      errormsg: "",
      phpurl: window.location.protocol + "//" + window.location.hostname + "/math/palindromecounter.php",
    }
  },
  mounted: function() {
    this.$refs.start.focus();
  },
  methods: {
    palindromeCounter: function() {

      // Reset error flag
      this.error = false;

      // Set counters and initial reversed number
      var cnt = 0;
      var s1 = this.start.toString();
      var s2 = this.start.split("").reverse().join("");
      var n;
      this.seq = this.start;

      // Stop after 100 iterations and raise error
      while (s1 != s2 && cnt <= 100) {
        n = parseInt(s1) + parseInt(s2);
        s1 = n.toString();
        s2 = s1.split("").reverse().join("");
        cnt++
        this.seq += " - " + n;
      }

      // Set the counter or show error messages if above 100
      if (cnt > 100) {
        this.error = true;
        this.errormsg = "More than 100 iterations are needed";
      } else {
        this.n = cnt;
      }

    },
  },
}
</script>
