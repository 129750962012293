<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      <div class="text-center">Properties of numbers</div>
    </div>
    <div class="mainpage">
      <div class="form-inline mb-2">
        <label class="form-label col-sm-2 col-md-3" for="x">Number to check</label>
        <input type='number' id="n" name="n" ref="n" v-model="n" min="1" max="10000000" class="form-control col-sm-10 col-md-6 mr-md-2" v-on:keyup.enter="getProperties">
        <input type="button" id="props" name="props" value="Get properties" class="col-md-2 btn btn-primary" v-on:click="getProperties">
      </div>
      <div class="card">
        <div class="card-header">Properties</div>
        <table>
          <tr><td width="50px"><div v-show="isfibonacci">&#x2713;</div><div v-show="!isfibonacci">&#x2717;</div></td>
            <td>Fibonacci: Sequence, each number is the sum of the previous two. It starts with 1, 1, 2, 3, ...</td></tr>
          <tr><td><div v-show="isprime">&#x2713;</div><div v-show="!isprime">&#x2717;</div></td>
            <td>Prime: A natural number greater than 1 that is not a product of two smaller natural numbers</td></tr>
          <tr><td><div v-show="!isprime">&#x2713;</div><div v-show="isprime">&#x2717;</div></td>
            <td>Composite: Numbers n of the form x*y for x > 1 and y > 1</td></tr>
          <tr><td><div v-show="isbiprime">&#x2713;</div><div v-show="!isbiprime">&#x2717;</div></td>
            <td>Biprime: Numbers which are the product of exactly two primes</td></tr>
          <tr><td><div v-show="isniven">&#x2713;</div><div v-show="!isniven">&#x2717;</div></td>
            <td>Niven or Hasrhad: Numbers which are divisable by the sum of their digits</td></tr>
          <tr><td><div v-show="issquarefree">&#x2713;</div><div v-show="!issquarefree">&#x2717;</div></td>
            <td>Squarefree: Number not divisable for square > 1</td></tr>
          <tr><td><div v-show="isevil">&#x2713;</div><div v-show="!isevil">&#x2717;</div></td>
            <td>Evil: Binary representation has even number of ones</td></tr>
          <tr><td><div v-show="!isevil">&#x2713;</div><div v-show="isevil">&#x2717;</div></td>
            <td>Odious: Binary representation has odd number of ones</td></tr>
          <tr><td><div v-show="isperfect">&#x2713;</div><div v-show="!isperfect">&#x2717;</div></td>
            <td>Perfect: Sigma(n) = 2n, where sigma(n) is the sum of the divisors of n</td></tr>
          <tr><td><div v-show="isdeficient">&#x2713;</div><div v-show="!isdeficient">&#x2717;</div></td>
            <td>Deficient: Sigma(n) &lt; 2n, where sigma(n) is the sum of the divisors of n</td></tr>
          <tr><td><div v-show="isabundant">&#x2713;</div><div v-show="!isabundant">&#x2717;</div></td>
            <td>Abundant: Sigma(n) &gt; 2n, where sigma(n) is the sum of the divisors of n</td></tr>
          <tr><td><div v-show="isnarcissistic">&#x2713;</div><div v-show="!isnarcissistic">&#x2717;</div></td>
            <td>Narcistic: Sum of digits ** (#digits) equals n</td></tr>
          <tr><td><div v-show="ispowerful">&#x2713;</div><div v-show="!ispowerful">&#x2717;</div></td>
            <td>Powerful: For every prime p dividing n, p**2 also divides n</td></tr>
          <tr><td><div v-show="islucky">&#x2713;</div><div v-show="!islucky">&#x2717;</div></td>
            <td>Lucky: see <a href="https://en.wikipedia.org/wiki/Lucky_number">Wikipedia</a> for an explanation</td></tr>
          <tr><td><div v-show="ishappy">&#x2713;</div><div v-show="!ishappy">&#x2717;</div></td>
            <td>Happy: see <a href="https://en.wikipedia.org/wiki/Happy_number">Wikipedia</a> for an explanation</td></tr>
          <tr><td><div v-show="isaspiring">&#x2713;</div><div v-show="!isaspiring">&#x2717;</div></td>
            <td>Aspiring: see <a href="https://oeis.org/A063769"> Online Encyclopedia of Integer Sequences</a> for an explanation</td></tr>
          <tr><td><div v-show="ispentagonal">&#x2713;</div><div v-show="!ispentagonal">&#x2717;</div></td>
            <td>Pentagonal: one of the sequence generated with a(n) = n*(3*n-1)/2</td></tr>
        </table>
      </div>
      <div>
        <p v-show="error" class="errormsg">Er is iets fout gegaan.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MathProperties',
  props: {
    msg: String
  },
  data: function () {
    return {
      title: "MathProperties",
      n: 0,
      result: 0,
      error: false,
      ispalindrome: false,
      isfibonacci: false,
      isprime: false,
      isbiprime: false,
      isniven: false,
      issquarefree: false,
      isevil: false,
      isperfect: false,
      isdeficient: false,
      isabundant: false,
      isnarcissistic: false,
      ispowerful: false,
      islucky: false,
      ishappy: false,
      isaspiring: false,
      ispentagonal: false,
      phpurl: window.location.protocol + "//"  + window.location.hostname + "/math/numberproperties.php",
    }
  },
  mounted: function() {
    this.$refs.n.focus();
  },
  methods: {
    getProperties: function() {
      this.error = false;
      let data = {
        n: this.n,
      };
      //console.log (JSON.stringify(data));
      fetch(this.phpurl, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
          this.ispalindrome = data.ispalindrome;
          this.isfibonacci = data.isfibonacci;
          this.isprime = data.isprime;
          this.isbiprime = data. isbiprime;
          this.isniven = data.isniven;
          this.issquarefree = data.issquarefree;
          this.isevil = data.isevil;
          this.isperfect = data.isperfect;
          this.isdeficient = data.isdeficient;
          this.isabundant = data.isabundant;
          this.isnarcissistic = data.isnarcissistic;
          this.ispowerful = data.ispowerful;
          this.islucky = data.islucky;
          this.ishappy = data.ishappy;
          this.isaspiring = data.isaspiring;
          this.ispentagonal = data.ispentagonal;
        })
        .catch((error) => {
          console.error('Error ', error);
          this.error = true;
        });
    },
  },
}
</script>
