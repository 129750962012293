<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      Sequences
    </div>
    <div class="mainpage">
      <div class="mb-2">
        These are just a few of ten of thousands of documented integer sequences. If the one you need is not here, try the <a href="http://oeis.org">
        Online Encyclopedia of Integer Sequences</a>. You can search OEIS on words, but also on the first few numbers of the sequence.
      </div>
      <div class="card">
        <div class="card-header">Conway</div>
        <div class="card-body">
          <div call="card-text">
            The Conway sequence is also known as the look-and-say sequence. To generate a member of the sequence from the previous member, read off the
            digits of the previous member, counting the number of digits in groups of the same digit.
            <ul>For example
              <li>1 is read as one 1 or 11</li>
              <li>11 is read as two 1 or 21</li>
              <li>21 is read as one 2 and one 1 or 1211</li>
            </ul>
            <div class="card-text form-inline">
              <label class="form-label col-sm-2 col-md-2 col-lg-1" for="start3">Start with</label>
              <input type='number' id="start3" name="start3" ref="start3" v-model="start3" min="0" class="form-control col-sm-10 col-md-3 col-lg-2">
              <label class="form-label col-sm-2 col-md-2 col-lg-1" for="n3">#iterations</label>
              <input type='number' id="n3" name="n3" ref="n3" v-model="n3" min="0" class="form-control col-sm-10 col-md-3 col-lg-2">
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Conway sequence" class="btn btn-primary" v-on:click="sequence('Conway')">
              </div>
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Reverse conway" class="btn btn-primary" v-on:click="sequence('Reverse Conway')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Hailstone</div>
        <div class="card-body">
          <div call="card-text">
            The Hailstone sequence is generated as follows. The sequence eventually always ends with 4,2,1 but nobody has been able to
            prove it mathematically. Take a positive number <i>n</i> and apply these rules:
            <ul>
              <li>If <i>n</i> is even divide it by two</li>
              <li>If <i>n</i> is odd multiply it with 3 and add 1</li>
            </ul>
          </div>
          <div class="card-text form-inline">
            <label class="form-label col-sm-2 col-md-2 col-lg-1" for="n">Start with</label>
            <input type='number' id="start" name="start" ref="start" v-model="start" min="0" class="form-control col-sm-10 col-md-3 col-lg-2">
            <label class="form-label col-sm-2 col-md-2 col-lg-1" for="x">#iterations</label>
            <input type='number' id="n" name="n" ref="n" v-model="n" min="0" class="form-control col-sm-6 col-md-3 col-lg-2">
            <div class="col-sm-4 col-md-4 col-lg-2 p-2">
              <input type="button" value="Hailstone sequence" class="btn btn-primary mr-2" v-on:click="sequence('Hailstone')">
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Niven or Harshad numbers</div>
        <div class="card-body">
          <div call="card-text">
            Numbers who are divisable by the sum of their digits.
            <div class="card-text form-inline">
              <label class="form-label col-sm-4 col-md-4 col-lg-2" for="n6">Number on position</label>
              <input type='number' id="n6" name="n6" ref="n6" v-model="n6" class="form-control col-sm-8 col-md-4 col-lg-2">
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Niven sequence" class="btn btn-primary mr-2" v-on:click="sequence('Niven')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Abundant numbers</div>
        <div class="card-body">
          <div call="card-text">
            Numbers where the sum of the divisors is greater than twice the number.
            <div class="card-text form-inline">
              <label class="form-label col-sm-4 col-md-4 col-lg-2" for="x">Number on position</label>
              <input type='number' id="n4" name="n4" ref="n4" v-model="n4" class="form-control col-sm-8 col-md-4 col-lg-2">
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Abundant sequence" class="btn btn-primary mr-2" v-on:click="sequence('Abundant')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Deficient numbers</div>
        <div class="card-body">
          <div call="card-text">
            Numbers where the sum of the divisors is smaller than twice the number.
            <div class="card-text form-inline">
              <label class="form-label col-sm-4 col-md-4 col-lg-2" for="n5">Number on position</label>
              <input type='number' id="n5" name="n5" ref="n5" v-model="n5" class="form-control col-sm-8 col-md-4 col-lg-2">
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Deficient sequence" class="btn btn-primary mr-2" v-on:click="sequence('Deficient')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Golomb</div>
        <div class="card-body">
          <div call="card-text">
            In mathematics, the Golomb sequence is a non-decreasing integer sequence where n-th term is equal to number of times n appears in the sequence.
            The first few values are 1, 2, 2, 3, 3, 4, 4, 4, 5, 5, 5, ……
            <div class="card-text form-inline">
              <label class="form-label col-sm-4 col-md-4 col-lg-2" for="n2">Number on position</label>
              <input type='number' id="n2" name="n2" ref="n2" v-model="n2" class="form-control col-sm-8 col-md-4 col-lg-2">
              <div class="col-md-4 col-lg-2 p-2">
                <input type="button" value="Golomb sequence" class="btn btn-primary mr-2" v-on:click="sequence('Golomb')">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Result {{seqname}}</div>
        <div class="card-text p-2">
          <div class="monospace">
            <p>The number on position {{pos}} is {{result}}.</p>
            <p>The sequence is: {{seq}}</p>
          </div>
        </div>
      </div>
      <p v-show="error" class="errormsg">{{errormsg}}.</p>
    </div>
  </div>
</template>

<script>

import * as mathsequences from '@/scripts/mathsequences.js'

export default {
  name: 'MathSequences',
  props: {
    msg: String
  },
  data: function () {
    return {
      title: "MathSequences",
      // Hailstone variables
      n: 1,
      start: 1,
      // Golomb variables
      n2: 1,
      // Conway variabels
      n3: 1,
      start3: 1,
      // Abundant variables
      n4: 1,
      // Deficient variables
      n5: 1,
      // Niven variabels
      n6: 1,
      // Global variables
      seqname: "",
      pos: 0,
      result: 0,
      seq: "",
      error: false,
      errormsg: "",
    }
  },

  mounted: function() {

    // Set input focus on the sequence passed as a parameter
    if (typeof(this.$route.params.sequence) != "undefined")
      switch (this.$route.params.sequence) {
        case "Hailstone" :
          this.$refs.n.focus();
          break;
        case "Golomb" :
          this.$refs.n2.focus();
          break;
        case "Conway" :
          this.$refs.n3.focus();
          break;
        default :
          this.$refs.n.focus();
      }
    else
      this.$refs.n.focus();
  },

  methods: {
    sequence: function(name) {
      var seq = null;
      switch (name) {
        case 'Hailstone' :
          seq = mathsequences.hailstone (this.start, this.n);
          this.pos = this.n;
          break;
        case "Golomb" :
          seq = mathsequences.golomb (this.n2);
          this.pos = this.n2;
          break;
        case "Abundant" :
          seq = mathsequences.abundant (this.n4);
          this.pos = this.n4;
          break;
        case "Deficient" :
          seq = mathsequences.deficient (this.n5);
          this.pos = this.n5;
          break;
        case "Conway" :
          seq = mathsequences.conway (this.start3, this.n3);
          this.pos = this.n3;
          break;
        case "Reverse Conway" :
          seq = mathsequences.revconway (this.start3, this.n3);
          this.pos = this.n3;
          break;
        case "Niven" :
          seq = mathsequences.niven (this.n6);
          this.pos = this.n6;
          break;
      }
      this.seqname = name;
      this.result = seq.n;
      this.seq = seq.seq;
    },
  },
}
</script>

<style scoped>

.monospace {
  font-family: "Lucida Console", Courier, monospace;
}

</style>
