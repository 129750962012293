<template>
  <div class="masthead">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col-12 text-center">
          <h1 class="font-weight-heavy">GC Cool Tools</h1>
        </div>
      </div>
    </div>
  </div>
  <div id="nav" class="col-12 text-center">
    <router-link to="/">Home</router-link> |
    <router-link to="/coord">Coordinates</router-link> |
    <router-link to="/texttoolbox">Text tools</router-link> |
    <router-link to="/cipher">Ciphers</router-link> |
    <router-link to="/codebook">Codebook</router-link> |
    <router-link to="/imagetools">Image tools</router-link> |
    <router-link to="/htmltools">HTML Scanner</router-link> |
    <router-link to="/math">Math tools</router-link> |
    <router-link to="/sudokusolver">Sudoku Solver</router-link> |
    <router-link to="/howtostart">How to</router-link> |
    <router-link to="/about">About</router-link>
  </div>
  <router-view/>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 10px;
}

#nav a {
  font-weight: bold;
  text-align: center;
  color: #39342B;
}

#nav a.router-link-exact-active {
  font-weight: normal;
}

a {
  color: #473868;
  font-weight: bold;
}

a:hover {
  color: #1D1A15;
}

body {
  background-color: #E6D1AC;
  color: #815E22;
}

.masthead {
	color: #2E1E03;
  height: 100px;
  padding: 10px;
  font-family: cursive;
  background-color: #E6D1AC;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.sectionhead {
	color: #2E1E03;
  font-size: 1.8em;
  max-height: 200px;
  text-align: center;
  margin-bottom: 5px;
}

.mainpage {
	min-height: 500px;
}

.btn {
  background-color: #2E1E03;
  border-style: none;
  color: #C1B7A6;
}

.btn:hover{
  box-shadow: 4px 4px #785E31;
  background-color: #E2AE15;
}

.btn.btn-secondary:hover {
  box-shadow: 4px 4px #785E31;
  background-color: #BCBBB8;
}

.btn.btn-primary:active {
  box-shadow: 4px 4px #785E31;
  background-color: #E2AE15 !important;
}

.btn-sm {
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 4px;
  padding-right: 4px;
}

.card {
  border-style: solid;
  border-width: 2px;
  border-color: #785E31;
  border-radius: 5px;
  margin-bottom: 5px;
}

.card-header {
  color: #E6D1AC;
  background-color: #785E31;
	text-align: left;
	font-size: 1.1em;
  padding: 5px;
}

.card-body {
  background-color: #E6D1AC;
  padding: 5px;
}

.card-text {
  font-size: 0.95em;
}

.form-control {
  background-color: #FBFAF7;
}

.mapcontrol {
  display: flex;
  flex-direction: row-reverse
}

.errormsg {
	color: red;
}

</style>
