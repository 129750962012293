<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">Enter the encrypted text, specify the original and substitute letters</div>
      <div class="form-inline mb-2">
        <label class="form-label col-sm-4 col-md-3 col-lg-2" for="from">Original letters</label>
        <input type='text' id="from" name="from" ref="from" v-model="from" size="50" class="form-control" v-on:keyup="replaceInput">{{error1}}
      </div>
      <div class="form-inline mb-2">
        <label class="form-label col-sm-4 col-md-3 col-lg-2" for="to">Replacements</label>
        <input type='text' id="to" name="to" ref="to" v-model="to" size="50" class="form-control" v-on:keyup="replaceInput">{{error2}}
      </div>
      <div class="form-inline mb-2">
        <label class="form-label col-sm-4 col-md-3 col-lg-2" for="highlightflag">Highlight</label>
        <select id="highlightflag" name="highlightflag" ref="highlightflag" v-model="highlightflag" class="form-control col-sm-4 col-md-3 col-lg-2" @change="replaceInput">
          <option value="red">Red</option>
          <option value="blue">Blue</option>
          <option value="yellow">Yellow</option>
          <option value="bold">Bold</option>
          <option value="uppercase">Uppercase</option>
        </select>
        <label class="form-label col-sm-4 col-md-3 col-lg-2" for="language">Language</label>
        <select id="language" name="language" ref="language" v-model="language" class="form-control col-sm-3" @change="setLanguage">
          <option value="en">English</option>
          <option value="nl">Dutch</option>
          <option value="de">German</option>
          <option value="fr">French</option>
          <option value="es">Spanish</option>
        </select>
      </div>
      <input type="button" id="analyze" name="analyze" value="Replace diacritics" class="btn btn-primary mb-2" v-on:click="removeDiacr">
      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 cols=50 v-model='message' v-on:keyup="replaceInput"></textarea>
          </div>
        </div>
        <p class="col-6 result" v-html="result"></p>
        <div class="col-12">
          <div class="form-inline mb-2">
            <input type="button" id="hint" name="hint" value="Get hints" class="btn btn-primary mr-2" v-on:click="printHints">
            <input type="button" id="hint" name="hint" value="Apply hints" class="btn btn-primary" v-on:click="applyHints">
          </div>
          <div>
            <div v-html="hints"></div>
          </div>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Some information on substitution ciphers</div>
        <div class="card-body">
          <div class="card-text mb-2">
            Basically any simple subsitution cipher can be cracked without much effort, using frequency analysis. The larger the text the easier it becomes.
            The letters in each language have a specific distribution, e.g. in English the letter e is the most common. So it is likely that the most common
            encrypted letter represents the E. The next common letter is T and so on. After you replaced a few letters start to look for common words.
          </div>
          <div class="card-text mb-2">
            You can also crack most codepages by replacing the symbols/codes with letters. Replace each unique symbol/code with a unique letter and then run this tool.
          </div>
          <div class="card-text">
            If you press Get Hints the tool will analyze the frequencies in the encoded text. And compare those with the frequencies of the selected language.
            This would normally group the replacements close to the original letters. If you feel lucky Apply hints will replace the eight most common letters already.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as textHelper from '@/scripts/texthelper.js';

export default {
  name: 'SmartReplace',
  props: {
    msg: String
  },
  data: function () {
    return {
      title: "Smart Replace",
      alphabet : "",
      from: "",
      to: "",
      error1: "",
      error2: "",
      input: "",
      message: "",
      highlightflag: "red",
      result: "",
      percentages : [],
      freq : "",
      freqperc : [],
      hints: "",
      language : "nl",
    }
  },
  mounted: function() {
    this.$refs.message.focus();
    for (let i=0; i<26; i++) this.percentages[i]= 0.00;
    this.setLanguage();
  },
  methods: {
    removeDiacr: function() {
      this.message = textHelper.removeDiacritics(this.message);
    },
    checkDouble: function (str) {
      for (var i=0; i < str.length; i++) {
        if (str.indexOf(str[i], i+1) > 0) return true;
      }
      return false;
    },
    setLanguage: function () {
      switch (this.language) {
        case "nl" :
          this.alphabet = "abcdefghijklmnopqrstuvwxyz";
          this.freq     = "enatirodslgvhkmubpwjzcfxyq";
          this.freqperc = ["18.9", "10.0", "7.5", "6.8", "6.5", "6.4", "6.1", "6.0", "3.7", "3.6", "3.4", "2.9", "2.4","2.3","2.2",
            "2.0", "1.58", "1.57","1.52","1.46","1.39","1.24", "0.81","0.04","0.04","0.02"];
          break;
        case "en" :
          this.alphabet = "abcdefghijklmnopqrstuvwxyz";
          this.freq     = "etaoinshrdlucmwfgypbvkxjqz";
          this.freqperc = ["13.0", "9.1", "8.2", "7.5", "7.0", "6.7", "6.3", "6.1", "6.0", "4.3", "4.0", "2.8", "2.8", "2.4", "2.4",
            "2.2", "2.0", "2.0", "1.97", "1.49", "0.98", "0.77", "0.15", "0.15", "0.10", "0.08"];
          break;
        case "de" :
          this.alphabet = "abcdefghijklmnopqrstuvwxyzäöüß";
          this.freq     = "ensraitdhulgcomwbfkzüvpäößjyxq";
          this.freqperc = ["16.4", "9.8", "7.2", "7.0", "6.5", "6.5", "6.2", "5.1", "4.6", "4.2", "3.4", "3.0", "2.7", "2.6", "2.5",
            "1.9", "1.8", "1.7", "1.4", "1.1", "1.0", "0.9", "0.7", "0.6", "0.4", "0.3", "0.27", "0.04", "0.03", "0.02"];
          break;
        case "fr" :
          this.alphabet = "abcdefghijklmnopqrstuvwxyzàâœçéêèëîïôùû";
          this.freq     = "esaitnruoldcmpvéqfbghjàxzèêyçkûùâwîôœëï";
          this.freqperc = ["14.7", "7.9", "7.6", "7.5", "7.2", "7.1", "6.7", "6.3", "5.8", "5.5", "3.7", "3.3", "3.0", "2.5", "1.83",
            "1.05", "1.36", "1.06", "0.90", "0.87", "0.74", "0.61", "0.49", "0.43", "0.33", "0.27", "0.22", "0.13", "0.09", "0.07",
            "0.06", "0.06", "0.05", "0.05", "0.05", "0.02", "0.02", "0.01", "0.01"];
          break;
        case "es" :
          this.alphabet = "abcdefghijklmnopqrstuvwxyzáéíñóúü";
          this.freq     = "eaosrnidltcmupbgvyqóíhfájzéñxüwúk";
          this.freqperc = ["12.2", "11.5", "8.6", "8.0", "6.8", "6.7", "6.2", "5.0", "4.9", "4.6", "4.0", "3.1", "2.9", "2.5", "2.2", "1.77", "1.14",
            "1.00", "0.88", "0.83", "0.73", "0.70", "0.69", "0.50", "0.49", "0.46", "0.43", "0.31", "0.22", "0.17", "0.02", "0.01", "0,01"];
          break;
      }
    },
    printHints : function () {
      // Analyze input
      var countletters = [];
      var idx;
      for (let i=0; i < this.alphabet.length; i++) countletters[i]=0;
      for (let i=0; i < this.message.length; i++) {
        idx = this.alphabet.indexOf(this.message[i].toLowerCase());
        if (idx >= 0) countletters[idx]++;
      }
      this.percentages = [];
      for (let i=0; i < this.alphabet.length; i++) {
        this.percentages[i] = { char : this.alphabet[i], count : countletters[i] / this.message.length * 100 };
      }
      this.percentages.sort((a, b) => { if (a.count > b.count) return -1; else if (a.count < b.count) return 1; else return 0; });
      var html = "<table class='table table-sm table-bordered text-center'><tr><td>Letter</td>";
      for (let i = 0; i < this.freq.length; i++) html+="<td>" + this.freq[i] + "</td>";
      html += "</tr><tr><td>Frequency</td>";
      for (let i = 0; i < this.freqperc.length; i++) html+="<td>" + this.freqperc[i] + "</td>";
      html += "</tr><tr><td>Hint letters</td>";
      for (let i = 0; i < this.percentages.length; i++) html+="<td>" + this.percentages[i].char + "</td>";
      html += "</tr><tr><td>Hint freq</td>";
      for (let i = 0; i < this.percentages.length; i++) html+="<td>" + this.percentages[i].count.toFixed(1) + "</td>";
      html += "</tr></table>";
      this.hints = html;

    },
    applyHints : function () {
      this.printHints();
      // Fill from and to here
      this.from = this.freq.slice(0,8);
      this.to = "";
      for (let i=0; i<8; i++) this.to += this.percentages[i].char;
      this.replaceInput();
    },
    replaceInput: function() {
      this.error1 = "";
      this.error2 = "";
      this.from = this.from.toLowerCase();
      this.to = this.to.toLowerCase();
      if (this.to.length != this.from.length) {
        this.error1 = "From / to must have same length";
        this.error2 = "From / to must have same length";
      } else if (this.checkDouble(this.to)) {
        this.error2 = "Double characters found";
      } else if (this.checkDouble(this.from)) {
        this.error1 = "Double characters found";
      } else {
        var html = "";
        var idx = 0;
        for (var i=0; i < this.message.length; i++) {
          idx = this.to.indexOf(this.message[i].toLowerCase());
          if (idx >= 0) {
            switch (this.highlightflag) {
              case 'red' :
              case 'yellow' :
              case 'blue' :
                html += '<span style="color: ' + this.highlightflag + '">' + ((this.message[i].toUpperCase() == this.message[i]) ? this.from[idx].toUpperCase() : this.from[idx]) + '</span>';
                break;
              case 'bold' :
                html += '<b>' + ((this.message[i].toUpperCase() == this.message[i]) ? this.from[idx].toUpperCase() : this.from[idx]) + '</b>';
                break;
              case 'uppercase' :
                html += this.from[idx].toUpperCase();
                break;
            }
          } else {
            html += this.message[i];
          }
        }
        this.result = html;
      }
    },
  },
}
</script>

<style scoped>
.result {
  word-wrap: break-word;
}
</style>
