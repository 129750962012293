<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      <div class="text-center">Decimals of &#x1D70B;, <i>e</i>, &#x1D70F;, &phi;, &gamma; &radic;2 and &radic;3</div>
    </div>
    <div class="mainpage">
      <div class="mb-2">
        This tool allows you to get the decimals of some well known numbers. The count starts at the first
        decimal (so to say after the dot). Select the start and end digit. The first 1,000,000 digits are available.
      </div>
      <div>
        <label class="form-label" for="number">Select a number</label>
        <div class="form-check">
          <input type="radio" id="number1" name="number" value="pi" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number1">&pi; or pi, starts with 3.141592653589...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number2" name="number" value="e" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number2">e, starts with 2.718281828459...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number3" name="number" value="tau" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number3">Tau &#x1D70F; or 2*&#x1D70B;, starts with 6.2831853071...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number4" name="number" value="phi" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number4">&phi; or phi or the golden ratio, starts with 1.61803398874989...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number5" name="number" value="euler" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number5">&gamma; or the Euler–Mascheroni constant, starts with 0.577215664901...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number6" name="number" value="sqrt2" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number6">&radic;2, starts with 1.414213562373095...</label>
        </div>
        <div class="form-check">
          <input type="radio" id="number7" name="number" value="sqrt3" class="form-check-input" v-model="number">
          <label class="form-check-label" for="number7">&radic;3, starts with 1.7320508075...</label>
        </div>
      </div>
      <div class="form-inline mt-4 mb-2">
        <label class="form-label mr-2" for="start">From digit</label>
        <input type='number' id="start" name="start" ref="start" v-model="start" min="0" max="1000000" class="form-control mr-2">
        <label class="form-label mr-2" for="end">to digit</label>
        <input type='number' id="end" name="end" ref="end" v-model="end" min="0" max="1000000" class="form-control mr-2" v-on:keyup.enter="getDigits">
        <input type="button" id="product" name="product" value="Get digits" class="btn btn-primary mr-2" v-on:click="getDigits">
      </div>
      <div class="card-text">The digits from {{start}} to {{end}} are {{result}}.</div>
      <p v-show="error" class="errormsg">Er is iets fout gegaan.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MathDigits',

  props: {
    msg: String
  },

  data: function () {
    return {
      title: "MathDigits",
      number: "pi",
      start: 1,
      end: 1,
      error: false,
      result: "",
      phpurl: window.location.protocol + "//"  + window.location.hostname + "/math/getdigits.php",
    }
  },

  mounted: function() {
    this.$refs.start.focus();
  },

  methods: {

    // Get the digits
    getDigits: function() {
      this.error = false;
      let data = {
        number: this.number,
        start: this.start,
        end: this.end,
        n: this.n,
      };

      // Call script on server
      fetch(this.phpurl, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            this.result = data.result;
            // console.log('Success', data)
        })
        .catch((error) => {
            console.error('Error ', error);
            this.error = true;
        });
    },

  },
}
</script>
