<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="form-inline mb-2">
        <input type="file" ref="file" name="file" id="file" class="form-control-file mb-2" @change="selectFile">
        <!-- <label for="imgurl" class="form-label mr-2">Or enter URL</label>
        <input type="text" ref="imgurl" name="imagurl" id="imgurl" v-model="fileurl" class="form-control" @change="selectURL"> -->
      </div>
      <div class="mb-2">
        <input type="button" id="flipX" name="flipX" value="Flip horizontal" class="btn btn-primary ml-2" v-on:click="doFlipY">
        <input type="button" id="flipY" name="flipY" value="Flip vertical" class="btn btn-primary ml-2" v-on:click="doFlipX">
        <input type="button" id="rotate" name="rotate" value="Rotate 90&deg; clockwise" class="btn btn-primary ml-2" v-on:click="doRotate">
      </div>
      <p v-show="error" class="errormsg">{{errormsg}}</p>
      <div class="row">
        <div class="col-8">
          <div class="text-center mb-2" v-if="imageData.length > 0">
            <img id="imgpreview" class="img-fluid rounded" v-bind:class="{ flipY : isFlipY, flipX : isFlipX, rotate90 : isRotate90, rotate180: isRotate180, rotate270: isRotate270 }" :src="imageData">
          </div>
          <v-map />
        </div>
        <div class="col-4">
          <div class="exifinfo card mb-2">
            <div class="card-header">Image info</div>
            <div class="card-text p-1">
              <table class="table table-sm table-borderless">
                <tr><td>Filename</td><td>{{Filename}}</td></tr>
                <tr><td>Type</td><td>{{Filetype}}</td></tr>
                <tr><td>Size</td><td>{{Filesize}}</td></tr>
                <tr><td>Width</td><td>{{ImageWidth}}</td></tr>
                <tr><td>Height</td><td>{{ImageHeight}}</td></tr>
                <tr><td>Date &amp; time</td><td>{{DateTime}}</td></tr>
                <tr><td>Date &amp; time created</td><td>{{DateTimeOriginal}}</td></tr>
              </table>
            </div>
            <div class="card-header">Camera info</div>
            <div class="card-text p-1">
              <table class="table table-sm table-borderless">
                <tr><td>Camera</td><td>{{Camera}}</td></tr>
                <tr><td>Lens</td><td>{{LensModel}}</td></tr>
                <tr><td>Aperture</td><td>{{Aperture}}</td></tr>
                <tr><td>Shutter</td><td>{{Shutter}}</td></tr>
              </table>
            </div>
            <div class="card-header">Descriptions</div>
            <div class="card-text p-1">
              <table class="table table-sm table-borderless">
                <tr><td>Title</td><td>{{Title}}</td></tr>
                <tr><td>Title 2</td><td>{{XPTitle}}</td></tr>
                <tr><td>Description</td><td>{{Description}}</td></tr>
                <tr><td>Description 2</td><td>{{ImageDescription}}</td></tr>
                <tr><td>Subject</td><td>{{XPSubject}}</td></tr>
                <tr><td>About</td><td>{{About}}</td></tr>
                <tr><td>Keywords</td><td>{{XPKeywords}}</td></tr>
                <tr><td>Comment</td><td>{{XPComment}}</td></tr>
              </table>
            </div>
            <div class="card-header">Author info</div>
            <div class="card-text p-1">
              <table class="table table-sm table-borderless">
                <tr><td>Artist</td><td>{{Artist}}</td></tr>
                <tr><td>Creator</td><td>{{Creator}}</td></tr>
                <tr><td>Author</td><td>{{XPAuthor}}</td></tr>
                <tr><td>Copyright</td><td>{{Copyright}}</td></tr>
              </table>
            </div>
            <div class="card-header">GPS data</div>
            <div class="card-text p-1">
              <table class="table table-sm table-borderless">
                <tr><td>GPS from camera</td><td>{{GPSfromCamera}}</td></tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ExifReader from 'exifreader'
import VMap from '@/components/inputs/VMap.vue'
import * as coords from '@/scripts/coords.js';

export default {
  name: 'ImageTools',

  props: {
    msg: String
  },

  components: {
    VMap,
  },

  data: function() {
    return {
      title: "Image tools",
      fileurl: "",
      imageData: "",
      isFlipX: false,
      isFlipY: false,
      isRotate90: false,
      isRotate180: false,
      isRotate270: false,
      error: false,
      errormsg: "",
      exifdata: "",
      tags: null,
      Filename: "",
      Filesize: "",
      Filetype: "",
      Title: "",
      XPTitle: "",
      XPAuthor: "",
      XPComment: "",
      XPSubject: "",
      XPKeywords: "",
      Copyright: "",
      DateTime: "",
      DateTimeOriginal: "",
      ImageWidth: 0,
      ImageHeight: 0,
      ImageDescription: "",
      Author: "",
      Artist: "",
      Description: "",
      About: "",
      Creator: "",
      Camera: "",
      LensModel: "",
      Aperture: "",
      Shutter: "",
      GPSfromCamera: "",
    }
  },

  mounted: function() {
    // Set focus on file input
    this.$refs.file.focus();
  },

  methods: {

    // Decode a UCS2 string
    decodeUCS2: function (a) {
      // For some stupid reason EXIF XP fields has UCS-2 strings which are two
      // bytes the lowest first, the highest second which match UTF-16 plane 1
      var s = "";
      for (let i = 0; i < a.length; i += 2) {
        s += String.fromCharCode (a[i+1] * 0xFF + a[i]);
      }
      return s;
    },

    // Display the EXIF data
    displayExif: function () {
      // Read the relevant fields and display those
      // Check first if they are defined before accessing them
      // XP fields are UCS2 encoded

      // Get sizes and date
      this.DateTime = (Object.prototype.hasOwnProperty.call(this.tags, "DateTime")) ? this.tags.DateTime.description : "No date found";
      this.DateTimeOriginal = (Object.prototype.hasOwnProperty.call(this.tags, "DateTimeOriginal")) ? this.tags.DateTimeOriginal.description : "No date found";
      this.ImageHeight = (Object.prototype.hasOwnProperty.call(this.tags, "Image Height")) ? this.tags["Image Height"].value : "No height found";
      this.ImageWidth = (Object.prototype.hasOwnProperty.call(this.tags, "Image Width")) ? this.tags["Image Width"].value : "No width found";

      // Get camera related info
      this.Camera = (Object.prototype.hasOwnProperty.call(this.tags, "Model")) ? this.tags.Model.description : "No model found";
      this.LensModel = (Object.prototype.hasOwnProperty.call(this.tags, "LensModel")) ? this.tags.LensModel.description : "No lens found";
      this.Aperture = (Object.prototype.hasOwnProperty.call(this.tags, "ApertureValue")) ? this.tags.ApertureValue.description : "No aperture found";
      this.Shutter = (Object.prototype.hasOwnProperty.call(this.tags, "ShutterSpeedValue")) ? this.tags.ShutterSpeedValue.description : "No shutter found";

      // Get descriptions
      this.Title = (Object.prototype.hasOwnProperty.call(this.tags, "title")) ? this.tags.title.description : "No title found";
      this.XPTitle = (Object.prototype.hasOwnProperty.call(this.tags, "XPTitle")) ? this.decodeUCS2(this.tags.XPTitle.value) : "No title found";
      this.Description = (Object.prototype.hasOwnProperty.call(this.tags, "description")) ? this.tags.description.description : "No description found";
      this.ImageDescription = (Object.prototype.hasOwnProperty.call(this.tags, "ImageDescription")) ? this.tags.ImageDescription.description : "No description found";
      this.About = (Object.prototype.hasOwnProperty.call(this.tags, "about")) ? this.tags.about.description : "No about found";
      this.XPComment = (Object.prototype.hasOwnProperty.call(this.tags, "XPComment")) ? this.decodeUCS2(this.tags.XPComment.value) : "No comments found";
      this.XPSubject = (Object.prototype.hasOwnProperty.call(this.tags, "XPSubject")) ? this.decodeUCS2(this.tags.XPSubject.value) : "No subject found";
      this.XPKeywords = (Object.prototype.hasOwnProperty.call(this.tags, "XPKeywords")) ? this.decodeUCS2(this.tags.XPKeywords.value) : "No keywords found";

      // Get artist related exifinfo
      this.Artist = (Object.prototype.hasOwnProperty.call(this.tags, "Artist")) ? this.tags.Artist.description : "No artist found";
      this.XPAuthor = (Object.prototype.hasOwnProperty.call(this.tags, "XPAuthor")) ? this.decodeUCS2(this.tags.XPAuthor.value) : "No author found";
      this.Creator = (Object.prototype.hasOwnProperty.call(this.tags, "creator")) ? this.tags.creator.description : "No creator found";
      this.Copyright = (Object.prototype.hasOwnProperty.call(this.tags, "Copyright")) ? this.tags.Copyright.description : "No copyright found";

      // GPS data
      // Set a marker on the map for the GPS coordinates
      if (Object.prototype.hasOwnProperty.call(this.tags, "GPSLatitude") && Object.prototype.hasOwnProperty.call(this.tags, "GPSLongitude")) {
        var marker = this.$store.state.L.marker([this.tags.GPSLatitude.description, this.tags.GPSLongitude.description]).addTo(this.$store.state.mymap);
        marker.bindPopup("GPS data from camera").openPopup();
        this.GPSfromCamera = coords.printCoordinateFromDMS( { lon: this.tags.GPSLongitude.description, lat: this.tags.GPSLatitude.description }, "N52 11.111 E4 11.111");
      } else {
        this.GPSfromCamera = "No GPS data from camera";
      }

    },

    // Flip the image by setting a transform class attribute
    doFlipX : function () {
      if (this.isFlipX) {
        // Restore flip
        this.isFlipX = false;
      } else {
        // Add flipX class
        this.isFlipX = true;
      }
    },

    // Flip the image by setting a transform class attribute
    doFlipY : function () {
      if (this.isFlipY) {
        // Restore flip
        this.isFlipY = false;
      } else {
        // Add flipY class
        this.isFlipY = true;
      }
    },

    // Rotate the image by setting a transform class attribute
    doRotate : function () {
      if (this.isRotate90) {
        this.isRotate90 = false;
        this.isRotate180 = true;
      } else if (this.isRotate180) {
        this.isRotate180 = false;
        this.isRotate270 = true;
      } else if (this.isRotate270) {
        this.isRotate270 = false;
      } else {
        this.isRotate90 = true;
      }
    },

    // Triggered when the file is loaded
    selectFile: function (event) {

      // Reset error flag
      this.error = false;

      // Get the input file
      var input = event.target;

      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onloadend = (e) => {

            try {
              // Display the image
              // Convert the input (an array buffer) to a BLOB object
              const blob = new Blob([e.target.result], {type: 'image/png'});
              // Create a URL from the Blob object and assign it to the image source
              this.imageData = URL.createObjectURL(blob);

              // Get the info from the file and display it
              this.Filename = input.files[0].name;
              this.Filetype = input.files[0].type;
              this.Filesize = input.files[0].size;

              // Now get the EXIF data (image has to be loaded first) and display it
              this.tags = ExifReader.load(e.target.result);
              this.displayExif();

            } catch(err) {
              console.log(err);
              this.error = true;
              this.errormsg = "Error loading image or EXIF data"
            }
        }

        // Start the reader job - read file as a array
        reader.readAsArrayBuffer(input.files[0]);
      }
    },

  }
}
</script>

<style scoped>

.flipY {
  transform: scaleY(-1);
}

.flipX {
  transform: scaleX(-1);
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.exifinfo {
  font-size: 0.85em
}

</style>
