<template>
  <div class="form-inline">
    <label for="listofalpha" class="form-label mr-2">Alphabets</label>
    <select class="custom-select ml-2" :value='alphabet' @input="updateAlphabet($event.target.value)">
      <option v-for="a in alphabets" :key="a" :value="a.name">{{a.name}} - {{a.alphabet}}</option>
    </select>
  </div>
</template>

<script>
import * as textHelper from '@/scripts/texthelper.js';

export default {
  props: ['alphabet'],
  data : function () {
    return {
      alphabets : null,
    }
  },
  mounted: function() {
    this.alphabets = textHelper.getExtAlphabets();
  },
  methods: {
    updateAlphabet: function (value) {
      this.$emit ('update:alphabet', value);
    },
  }
}
</script>
