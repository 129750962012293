<template>
  <div id="nav" class="text-center">
    <router-link to="/math/baseconvertor">Base convertor</router-link> |
    <router-link to="/math/countdigits">Get decimals of Pi and others</router-link> |
    <router-link to="/math/fibonacci">Fibonacci</router-link> |
    <router-link to="/math/gcdandlcm">Prime factors, GCD and LCM</router-link> |
    <router-link to="/math/numberproperties">Number properties</router-link> |
    <router-link to="/math/nimbers">Nimbers</router-link> |
    <router-link to="/math/sequences">Sequences</router-link> |
    <router-link to="/math/palindromecounter">Palindrome</router-link>
  </div>
  <router-view :key="$route.path" />
  <div v-if="['Math'].includes($route.name)">
    <div class="container">
      <va-item v-bind:showitem='show[0]' v-on:toggle='toggleItem(0)'>
        <template v-slot:header>Base converter</template>
        <template v-slot:content>
          <router-link to="/math/baseconvertor">Base converter</router-link> converts any base between 2 and 36. You can select what characters to use. A great way of translating words into numbers and vice versa. Remember that leading zeroes are not printed.<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[1]' v-on:toggle='toggleItem(1)'>
        <template v-slot:header>Decimals of &#x1D70B;, <i>e</i>, &#x1D70F;, &phi;, &gamma; and other numbers</template>
        <template v-slot:content>
          <router-link to="/math/countdigits">Decimals of</router-link> pi, e, golden ratio, Euler–Mascheroni, sqrt(2), sqrt(3) (maximum 1,000,000 decimals)<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[2]' v-on:toggle='toggleItem(2)'>
        <template v-slot:header>Fibonacci</template>
        <template v-slot:content>
            <router-link to="/math/fibonacci">Fibonacci</router-link>. Get the n-th number or check if a number is in the sequence. The first 500 Fibonacci numbers are available.<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[3]' v-on:toggle='toggleItem(3)'>
        <template v-slot:header>Prime factors, Greatest Common Divisor &amp; Least Common Multiple</template>
        <template v-slot:content>
          <router-link to="/math/gcdandlcm">Prime factors, GCD and LCM</router-link>. Find the greatest common divisor and least common
            multiple of two or more numbers. Also shows the prime factors of each number.<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[4]' v-on:toggle='toggleItem(4)'>
        <template v-slot:header>Number properties</template>
        <template v-slot:content>
          <router-link to="/math/numberproperties">Number properties</router-link> tells you the properties of a number, such as prime, coprime and a lot of lesser known properties<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[5]' v-on:toggle='toggleItem(5)'>
        <template v-slot:header>Nimbers</template>
        <template v-slot:content>
          <router-link to="/math/nimbers">Nimbers</router-link> are not numbers, but you can add and multiply them. They are used in combinatorial game theory.<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[6]' v-on:toggle='toggleItem(6)'>
        <template v-slot:header>Sequences</template>
        <template v-slot:content>
          <router-link to="/math/sequences">Sequences</router-link>.
          A collection of sequences. <b>Hailstone</b> is a rather strange sequence which always ends with 4,2,1.
          <b>Golomb</b> sequence is a non-decreasing integer sequence where n-th term is equal to number of times n appears in the sequence.
          <b>Conway sequence</b> is also known as the hear and say sequence.
          <b>Niven or Harshad</b> number divisable by the sum of their digits.
          <b>Abundant</b> numbers and <b>deficient</b> numbers.
        </template>
      </va-item>
      <va-item v-bind:showitem='show[7]' v-on:toggle='toggleItem(7)'>
        <template v-slot:header>Palindrome</template>
        <template v-slot:content>
          <router-link to="/math/palindromecounter">Palindrome</router-link> how many additions does it take for a number to become a palindrome?<br>
        </template>
      </va-item>
    </div>
  </div>
</template>

<script>

import VaItem from "@/components/inputs/VaItem.vue"

export default {
  name: 'Mathtools',

  props: {
    msg: String
  },

  components: {
    VaItem,
  },

  data: function () {
    return {
      title: "Mathtools",
      show: [true, true, true, true, true, true, true, true]
    }
  },

  methods: {

    toggleItem: function(itemnr) {
      this.show[itemnr] = !this.show[itemnr];
    }

  },
}
</script>
