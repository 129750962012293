<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div ref="listofalpha" class="form-inline mb-2">
        <v-alphabets v-model:alphabet="selectedalphabet"></v-alphabets>
      </div>
      <div class="form-inline mb-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" name="reverse" id="reverse" v-model="reverse" class="custom-control-input ml-2">
          <label for="reverse" class="custom-control-label ml-2">Reverse order Z&rightarrow;A</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" name="startatzero" id="startatzero" v-model="startatzero" class="custom-control-input ml-2">
          <label for="startatzero" class="custom-control-label ml-2">Start at zero (A=0 or Z=0 with reverse)</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" name="leadzero" id="leadzero" v-model="leadzero" class="custom-control-input ml-2">
          <label for="leadzero" class="custom-control-label ml-2">Always use leading zero below 10</label>
        </div>
      </div>
      <div class="form-group">
        <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 v-model='message'></textarea>
      </div>
      <input type="button" id="texttonumbers" name="texttonumbers" value="Text to numbers" class="btn btn-primary mb-2 mr-2" v-on:click="textToNumbers">
      <input type="button" id="numberstotext" name="numberstotext" value="Numbers to text" class="btn btn-primary mb-2 mr-2" v-on:click="numbersToText">
      <input type="button" id="remove" name="remove" value="Replace diacritics" class="btn btn-primary mb-2 mr-2" v-on:click="removeDiacr">
      <div class="card">
        <div class="card-header">Result</div>
        <div class="card-text p-2">{{result}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import * as textHelper from '@/scripts/texthelper.js';
import VAlphabets from '@/components/inputs/VAlphabets.vue'

export default {
  name: 'TextAnalyze',

  props: {
    msg: String,
  },

  data: function () {
    return {
      title: "Analyze text",
      message: "",
      selectedalphabet: "English",
      reverse : false,
      startatzero : false,
      leadzero: false,
      result : "Result",
    }
  },

  components: {
    VAlphabets,
  },

  mounted: function() {
    this.$refs.listofalpha.focus();
  },

  methods: {

    // Ruthless replace all diacritics
    removeDiacr: function() {
      this.message = textHelper.removeDiacritics(this.message);
    },

    // Convert text to numbers
    textToNumbers : function () {

      // Get the selected alphabet using the name
      var alpha = textHelper.getAlphabet(this.selectedalphabet);
      var mod = Math.floor(alpha.length / 10);
      if (this.startatzero) mod--;
      var html = "";
      var idx = 0;
      for (let i=0; i < this.message.length; i++) {
        idx = alpha.indexOf(this.message[i]);
        if (idx >= 0) {
          //if (!this.reverse && this.startatzedo) do nothing
          if (!this.reverse && !this.startatzero) idx++;
          if (this.reverse && this.startatzero) idx = alpha.length - idx - 1;
          if (this.reverse && !this.startatzero) idx = alpha.length - idx;
          if (!this.leadzero)
            (idx <= mod) ? html+= "0" + idx.toFixed(0) : html+= idx.toFixed(0);
          else
            (idx <= 9) ? html+= "0" + idx.toFixed(0) : html+= idx.toFixed(0);
        } else {
          html += this.message[i];
        }
      }
      this.result = html;
    },

    // Convert numbers to text
    numbersToText : function() {
      var alpha = textHelper.getAlphabet(this.selectedalphabet);
      var mod = Math.floor(alpha.length / 10);
      if (this.startatzero) mod--;
      var html = "";
      var d1 = 0;
      var d2 = 0;
      var pos = 0;
      for (let i=0; i < this.message.length; i++) {
        if ("0123456789".indexOf(this.message[i]) >= 0) {
          d1 = parseInt(this.message[i]);
          if (d1 <= mod) {
            d2 = parseInt(this.message[++i]);
            pos = d1 * 10 + d2;
          } else {
            pos = d1;
          }
          //if (!this.reverse && this.startatzedo) do nothing
          if (!this.reverse && !this.startatzero) pos--;
          if (this.reverse && this.startatzero) pos = alpha.length - pos - 1;
          if (this.reverse && !this.startatzero) pos = alpha.length - pos;
          html += alpha[pos];
        } else {
          html += this.message[i];
        }
      }
      this.result = html;
    },

  },
}
</script>

<style scoped>
</style>
