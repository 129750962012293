<template>
  <div id="nav" class="text-center">
    <router-link to="/texttoolbox/textwordvalue">Word value</router-link> |
    <router-link to="/texttoolbox/textcodes">Characters &amp; codes</router-link> |
    <router-link to="/texttoolbox/smartreplace">Smart replace</router-link> |
    <router-link to="/texttoolbox/textanalyzer">Analyze text</router-link> |
    <router-link to="/texttoolbox/texttonumbers">Text to numbers</router-link> |
    <router-link to="/texttoolbox/textkeyboards">Keyboards</router-link>
  </div>
  <router-view :key="$route.path" />
  <div v-if="['TextToolbox'].includes($route.name)">
    <div class="container">
      <va-item v-bind:showitem='show[0]' v-on:toggle='toggleItem(0)'>
        <template v-slot:header>Word value &amp; square root</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/textwordvalue">Word value or square root</router-link></strong>: Select from a range of available
          alphabets. Optionally use reverse (Z=26 - A=1) and/or start at zero (A=0 or Z=0).<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[1]' v-on:toggle='toggleItem(1)'>
        <template v-slot:header>Character Codes</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/textcodes">Character Codes</router-link></strong>
          Computers don't use characters, they use 0 and 1 (binary codes). Supports ASCII, UTF-16, alt codes and EBCDIC, .
          Supports binary, octal, decimal and hexadecimal. As an added bonus converts those numbers.
          Also supports telegraphy, telex codes Baudot (ITA-1) and Murray (ITA-2) and the Cyrillic Murray (MTK-2).
        </template>
      </va-item>
      <va-item v-bind:showitem='show[2]' v-on:toggle='toggleItem(2)'>
        <template v-slot:header>Smart replace</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/smartreplace">Smart replace</router-link></strong> suggest replacements based on letter frequencies. It is a
          great tool to crack encoded text. You can also use it to crack unknown codepages by using different letters for different codes and the then use this tool.<br>
        </template>
      </va-item>
      <va-item v-bind:showitem='show[3]' v-on:toggle='toggleItem(3)'>
        <template v-slot:header>Analyze text</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/textanalyzer">Analyzer</router-link></strong> counts words, lines and characters.
          Also count occurences and percentages of alphabetic, lowercase, uppercase, digits and other characters.
        </template>
      </va-item>
      <va-item v-bind:showitem='show[4]' v-on:toggle='toggleItem(4)'>
        <template v-slot:header>Text to numbers &amp; numbers to text</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/textanalyzer">Text to numbers</router-link></strong>
          and numbers to text. Using the selected alphabet converts words to numbers or numbers to words. You can use reversed order of the alphabet. You can start
          at zero instead of one. Optionally always use a leading zero for positions below 10 (if not leading zeroes are only printed when needed).
        </template>
      </va-item>
      <va-item v-bind:showitem='show[5]' v-on:toggle='toggleItem(5)'>
        <template v-slot:header>Keyboards</template>
        <template v-slot:content>
          <strong><router-link to="/texttoolbox/textkeyboards">Keyboards</router-link></strong> switch between QWERTY, AZERTY, QWERZ, DVORAK, Colemak and Cyrillic layouts.
        </template>
      </va-item>
    </div>
  </div>
</template>

<script>

import VaItem from '@/components/inputs/VaItem.vue'

export default {
  name: 'TextTools',

  props: {
    msg: String
  },

  components: {
    VaItem,
  },

  data: function () {
    return {
      title: "TextTools",
      show: [true, true, true, true, true, true],
    }
  },

  methods: {

    toggleItem: function(itemnr) {
      this.show[itemnr] = !this.show[itemnr];
    },

  },
}
</script>
