<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">Nimbers are not numbers, but you can add and multiply them. They are used in combinatorial game theory (CGT).</div>
      <div class="form-inline mb-2">
        <label class="form-label mr-2" for="x">Value of X</label>
        <input type='number' id="x" name="x" ref="x" v-model="x" class="form-control mr-2">
        <label class="form-label mr-2" for="y">Value of Y</label>
        <input type='number' id="y" name="y" ref="y" v-model="y" class="form-control mr-2" v-on:keyup.enter="nimberProduct">
      </div>
      <div>
        <input type="button" id="product" name="product" value="Calculate" class="btn btn-primary mb-2" v-on:click="nimberProduct">
      </div>
      <div class="card">
        <div class="card-header">Product &amp; sum</div>
        <div class="card-text p-2">
          The nimber sum is <strong>{{sum}}</strong><br>
          The nimber product is <strong>{{prod}}</strong>
        </div>
      </div>
      <p v-show="error" class="errormsg">Er is iets fout gegaan.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MathNimbers',

  props: {
    msg: String
  },

  data: function () {
    return {
      error: false,
      title: "Nimbers",
      x: 0,
      y: 0,
      prod: 0,
      sum: 0,
      phpurl: window.location.protocol + "//"  + window.location.hostname + "/math/nimbermultiplication.php",
    }
  },

  mounted: function() {
    this.$refs.x.focus();
  },

  methods: {

    // Calculate the nimber product and sum
    nimberProduct: function() {
      this.error = false;
      let data = {
        x: this.x,
        y: this.y,
      };

      // Call php script on server
      fetch(this.phpurl, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            this.prod = data.nimberproduct;
            this.sum = data.nimbersum;
            // console.log('Success', data)
        })
        .catch((error) => {
            console.error('Error ', error);
            this.error = true;
        });
    },

  },
}
</script>

<style scoped>
</style>
