<template>
  <div class="howtostart">
    <Howto msg="How to solve mystery caches"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Howto from '@/components/Howto.vue'

export default {
  name: 'Howtostart',
  components: {
    Howto
  }
}
</script>
