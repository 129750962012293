<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">
        There are hunderds of different keyboards around. So a text typed on one keyboard will result in different
        text if typed on another. Use this tool to convert from one to the other.
      </div>
      <div class="form-inline mb-2">
        <label class="form-label mr-2" for="keyboards">Input keyboard</label>
        <v-keyboards id="keyboards" v-model:keyboard="fromkeyboard"></v-keyboards>
      </div>
      <div>
        <div class="form-group">
          <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 v-model='message' @input="translateKeyboard"></textarea>
        </div>
        <div class="form-inline mb-2">
          <input type="button" id="convert" name="convert" value="Convert" class="btn btn-primary mr-2" v-on:click="translateKeyboard">
          <label class="form-label mr-2" for="keyboards2">Output keyboard</label>
          <v-keyboards id="keyboards2" v-model:keyboard="tokeyboard" @change="translateKeyboard"></v-keyboards>
        </div>
      </div>
      <div class="card">
        <div class="card-header">Result</div>
        <div class="card-text p-2">{{result}}</div>
      </div>
      <p v-show="error" class="errormsg mt-2">{{errormsg}}</p>
    </div>
  </div>
</template>

<script>
import VKeyboards from '@/components/inputs/VKeyboards.vue'
import * as keyboards from '@/scripts/keyboards.js'

export default {
  name: 'TextKeyboards',

  props: {
    msg: String,
  },

  data: function () {
    return {
      title: "Keyboards",
      message: "",
      result : "Result",
      fromkeyboard : "QWERTY",
      tokeyboard: "DVORAK2",
      error: false,
      errormsg: "",
    }
  },

  components: {
    VKeyboards,
  },

  mounted: function() {
    this.$refs.message.focus();
  },

  methods: {

    // Translate form one keyboard to another
    translateKeyboard : function () {

      // Reset error flag
      this.error = false;

      try {
        // Convert the input
        this.result = keyboards.convertKeyboard (this.message, this.fromkeyboard, this.tokeyboard);
      } catch (e) {
        this.error = true;
        this.errormsg = "Keyboard could not be converted, please check";
        console.log(e);
      }
    },

  },
}
</script>

<style scoped>
</style>
