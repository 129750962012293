<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="form-inline mb-2">
        <v-alphabets-ext id="listofalpha" ref="listofalpha" v-model:alphabet="selectedalphabet" @change="wordValue"></v-alphabets-ext>
      </div>
      <div class="form-inline mb-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" name="reverse" id="reverse" v-model="reverse" class="custom-control-input ml-2" @change="wordValue">
          <label for="reverse" class="custom-control-label ml-2">Reverse order Z&rightarrow;A</label>
        </div>
        <div class="custom-control custom-checkbox">
          <input type="checkbox" name="startatzero" id="startatzero" v-model="startatzero" class="custom-control-input ml-2" @change="wordValue">
          <label for="startatzero" class="custom-control-label ml-2">Start at zero (A=0 or Z=0 with reverse)</label>
        </div>
      </div>
      <div class="form-group">
        <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 v-model='message' @input="wordValue"></textarea>
      </div>
      <input type="button" id="analyze" name="analyze" value="Calculate values" class="btn btn-primary mb-2 mr-2" v-on:click="wordValue">
      <input type="button" id="remove" name="remove" value="Replace diacritics" class="btn btn-primary mb-2 mr-2" v-on:click="removeDiacr">
      <input type="button" id="remove" name="remove" value="Show/hide alphabet" class="btn btn-primary mb-2 mr-2" v-on:click="showTable">
      <div v-show="showvalues">
        <table class="table table-borderless table-sm">
          <tr><td v-for="s in values" :key="s">{{s[0]}}</td></tr>
          <tr><td v-for="s in values" :key="s">{{s[1]}}</td></tr>
        </table>
      </div>
      <div v-html="result" class = "col-12 card-text"></div>
      <div class="card">
        <div class="card-header">Some information on alphabets</div>
        <div class="card-body">
          <div class="card-text mb-2">
            The calculator uses the official alphabets. So in some languages diacritics are separate letters in the alphabet (e.g. Ä in German or Å in Swedish) and
            in some languages they represent the original letter (e.g. é or ô in French). The <b>remove diacritics</b> however is merciless and replaces
            all diacritics, which is okay in most cases. But for languages were diacritics are separate letters (like German or Swedish) you better not
            use Remove Diacritics.
          </div>
          <div class="card-text mb-2">
            The Greek and Cyrillic (such as Russian) alphabets have characters that look exactly like their Western versions, such as A or B. But they are different,
            they have different character codes. When typed with a Western keyboard they will still resolve to a value of zero. If you would copy for example
            place names from Wikipedia (e.g. Амстердам) the right symbols are used and the values are calculated correctly.
          </div>
          <div class="card-text">
            The Scrabble values used are those of the latest known version. There are quite a few other versions out there. If you want the values of one
            those, you can find them on <a href="https://en.wikipedia.org/wiki/Scrabble_letter_distributions">Wikipedia</a>, but you have to do the hard
            work yourself.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as textHelper from '@/scripts/texthelper.js';
import VAlphabetsExt from '@/components/inputs/VAlphabetsExt.vue'

export default {
  name: 'TextAnalyze',

  props: {
    msg: String,
  },

  data: function () {
    return {
      title: "Word Value & Square root",
      showvalues: false,
      values: null,
      message: "",
      alphabets: "",
      selectedalphabet: "English",
      reverse : false,
      startatzero : false,
      result : "",
    }
  },

  components: {
    VAlphabetsExt,
  },

  mounted: function() {
    // this.$refs.listofalpha.focus();
  },

  methods: {

    // Calculate the square root of a number
    squareRoot : function (n) {
      var total = n;
      while (total > 9) {
        n = total;
        total = 0;
        while (n > 9) {
          total += (n % 10);
          n = Math.floor(n / 10);
        }
        total += n;
      }
      return total;
    },

    // Remove diacritics
    removeDiacr: function() {
      this.message = textHelper.removeDiacritics(this.message);
    },

    // Load the tablie with row 0 the letters and row 1 the values
    showTable: function () {
      this.values = textHelper.getTable(this.selectedalphabet, this.reverse, this.startatzero);
      this.showvalues = !this.showvalues;
    },

    // Calculate the word values and square root of the entire text and each word individually
    wordValue : function() {

      // Check if we need to display table with values first
      if (this.showvalues) { this.showTable(); this.showvalues = !this.showvalues }

      // Do nothing if message is empty
      if (this.message.length == 0) return;

      //  Calculate value of the entire text
      var value = textHelper.wordValue(this.message, this.reverse, this.startatzero, this.selectedalphabet);
      var wordvalues = [ { name : "All text", value : value, squareroot : this.squareRoot(value)} ];

      // Split message in words
      var words = this.message.match(/([^\s.,:;]+)/ug);

      // Calculate values for each word
      for (let i=0; i < words.length; i++) {
        value = textHelper.wordValue(words[i], this.reverse, this.startatzero, this.selectedalphabet);
        wordvalues.push ( { name : words[i], value : value, squareroot : this.squareRoot(value)} );
      }

      // Display table with all values
      var html = "<table class='table table-sm table-striped'><thead><tr><th scope='col'>Word(s)</th><th class='text-center' scope='col'>Value</th><th class='text-center' scope='col'>Square root</th></tr></thead>";
      for (let i = 0; i < wordvalues.length; i++) {
        html += "<tr";
        if (i==0) html += " style='color:red'";
        html += "><th scope='row'>" + wordvalues[i].name + "</th><td class='text-center'>" + wordvalues[i].value + "</td><td class='text-center'>" + wordvalues[i].squareroot + "</td></tr>";
      }
      html += "</table>";
      this.result = html;

    },
  },
}

</script>

<style scoped>
</style>
