<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">
        Scan the HTML of a geocache to find hidden messages, links, comments or images. Copy the page source in below.
      </div>
      <div class="form-group">
        <textarea id="gchtml" name="gchtml" class="form-control" ref="gchtml" placeholder="Paste HTML" rows=3 v-model='gchtml'></textarea>
      </div>
      <div class="form-inline mb-2">
        <input type="button" id="scan" name="scan" value="Scan" class="btn btn-primary mr-2" v-on:click="scanHTML">{{scanresult}}
      </div>
      <div class="card" v-show="links.length> 0">
        <div class="card-header">Links found - select to open the link</div>
        <div class="card-body">
          <select class="custom-select" v-model="selectedlink" @change="openLink()">
            <option v-for="l in links" :key="l" :value="l.url">{{l.name}} - {{l.url}}</option>
          </select>
        </div>
      </div>
      <div class="card" v-show="images.length > 0">
        <div class="card-header">Images found - select to view the image</div>
        <div class="card-body">
          <select class="custom-select" v-model="selectedimage" @change="openImage()">
            <option v-for="i in images" :key="i" :value="i.url">{{i.name}} - {{i.url}}</option>
          </select>
        </div>
      </div>
      <div class="card" v-show="comments.length > 0">
        <div class="card-header">(Hidden) comments found</div>
        <div class="card-text p-2" v-html="comments"></div>
      </div>
      <div class="card" v-show="whites.length > 0">
        <div class="card-header">White text found</div>
        <div class="card-text p-2" v-html="whites"></div>
      </div>
      <div class="card" v-show="strongs.length > 0">
        <div class="card-header">Strong text</div>
        <div class="card-text p-2" v-html="strongs"></div>
      </div>
      <div class="card" v-show="bolds.length > 0">
        <div class="card-header">Bold text</div>
        <div class="card-text p-2" v-html="bolds"></div>
      </div>
      <div class="card" v-show="italics.length > 0">
        <div class="card-header">Italic text</div>
        <div class="card-text p-2" v-html="italics"></div>
      </div>
      <div class="card" v-show="sups.length > 0">
        <div class="card-header">Superscript text</div>
        <div class="card-text p-2" v-html="sups"></div>
      </div>
      <div class="card" v-show="subs.length > 0">
        <div class="card-header">Subscript text</div>
        <div class="card-text p-2" v-html="subs"></div>
      </div>
      <p v-show="error" class="errormsg mt-2">{{errormsg}}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HtmlParser',

  props: {
    msg: String,
  },

  data: function () {
    return {
      title: "Scan HTML",
      gchtml: "",
      scanresult: "",
      error: false,
      errormsg: "",
      cacheNode: null,
      images: [],
      selectedimage: "",
      links: [],
      selectedlink: "",
      comments: [],
      strongs: "",
      whites: [],
      bolds: "",
      italics: "",
      sups: "",
      subs: "",
    }
  },

  mounted: function() {
    this.$refs.gchtml.focus();
  },

  methods: {

    // Open a selected link from the dropdown with links
    openLink: function () {
        window.open(this.selectedlink);
    },

    // Open an image from the dropdown with images
    openImage: function () {
        window.open(this.selectedimage);
    },

    // Get all the nodes with a certain tag
    listTags: function (tag) {
      var nodes = this.cacheNode.getElementsByTagName(tag);
      var tags ="";
      for (let node of nodes) {
        // console.log(node);
        tags += node.textContent + " ";
      }
      return tags;
    },

    // Scan the HTML
    scanHTML: function () {

      // Reset error flag
      this.error = false;
      this.result = "";
      this.scanresult = "";

      try {
        // Scan the XML
        var parser = new DOMParser();
        var xmlTree = parser.parseFromString(this.gchtml,"text/html");

        // First find the div with id cachedetails
        this.cacheNode = xmlTree.getElementById("ctl00_ContentBody_LongDescription");

        // Check if cacheNode exists
        if (!this.cacheNode) {
          this.error = true;
          this.errormsg = "No cache details found, please load correct HTML";
          return;
        }

        // Find the links
        this.links = [];
        var nodes = this.cacheNode.getElementsByTagName("a");
        for (let node of nodes) {
          // console.log(node);
          this.links.push({ name: node.textContent, url: node.getAttribute("href") });
        }

        // Find the images
        this.images = [];
        nodes = this.cacheNode.getElementsByTagName("img");
        for (let node of nodes) {
          // console.log(node);
          this.images.push({ name: node.getAttribute("alt"), url: node.getAttribute("src") });
        }

        // Find the comments using XPath
        var x = xmlTree.evaluate("//span[@id='ctl00_ContentBody_LongDescription']//comment()", xmlTree, null, XPathResult.ANY_TYPE, null);
        var comment = x.iterateNext();
        this.showcomments = (comment != null);
        this.comments = "";
        while (comment) {
          this.comments += comment.textContent + "<br>";
          comment = x.iterateNext();
        }

        // Find white text using XPath matches style and font white and font FFFFFF
        x = xmlTree.evaluate(
          "//span[@id='ctl00_ContentBody_LongDescription']//font[@color='white'] | " +
          "//span[@id='ctl00_ContentBody_LongDescription']//font[@color='#FFFFFF'] | " +
          "//span[@id='ctl00_ContentBody_LongDescription']//*[contains(@style,'color:white') or contains(@style,'color:#FFFFFF')]", xmlTree, null, XPathResult.ANY_TYPE, null);
        var white = x.iterateNext();
        this.showwhites = (white != null);
        this.whites = "";
        while (white) {
          this.whites += white.textContent + "<br>";
          white = x.iterateNext();
        }

        // Find strong printed letters
        this.strongs = this.listTags("strong");
        // Find bold printed letters
        this.bolds = this.listTags("b");
        // Find italic letters
        this.italics = this.listTags("i");
        // Find superscript letters
        this.sups = this.listTags("sup");
        // Find subscript letters
        this.subs = this.listTags("sub");

        // Display scan completed messages
        this.scanresult = "Scan completed, details below (could be none)";

      } catch (e) {
        this.error = true;
        this.errormsg = "Error loading HTML";
        console.log(e);
      }
    },
  },
}

</script>

<style scoped>
</style>
