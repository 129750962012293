<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">This tool finds the Greatest Common Divisor (GCD) and least common multiple (LCM). It will also list the prime factors of each number.</div>
      <div class="form-inline mb-2">
        <label class="form-label col-sm-5 col-md-2" for="input">Numbers</label>
        <input type='text' id="input" name="input" ref="input" v-model="input" min="0" class="form-control col-sm-7 col-md-6 mr-md-2" v-on:keyup.enter="getGCDandLCM">
        <input type="button" id="gcdlcm" name="gcdlcm" value="Go" class="col-md-2 btn btn-primary" v-on:click="getGCDandLCM">
      </div>
      <div class="card">
        <div class="card-header">Greatest Common Divisor (GCD) &amp; least common multiple (LCM)</div>
        <div class="p-2 card-text">
          The GCD is <strong>{{gcd}}</strong>.<br>
          The LCM is <strong>{{lcm}}</strong>.
        </div>
      </div>
      <div class="card">
        <div class="card-header">Prime factors</div>
        <div class="p-2 card-text">
          <table class="table table-sm table-borderless">
            <tr v-for="a in primes" :key="a" :value="a.n"><td>{{a.n}}</td><td>{{a.primes}}</td></tr>
          </table>
        </div>
      </div>
      <p v-show="error" class="errormsg">Er is iets fout gegaan.</p>
    </div>
  </div>
</template>

<script>
import * as mathsequences from '@/scripts/mathsequences.js'

export default {
  name: 'MathGCDandLCM',

  props: {
    msg: String
  },

  data: function () {
    return {
      title: "Prime factors, GCD and LCM",
      input: "",
      error: false,
      gcd: 0,
      lcm: 0,
      primes: []
    }
  },

  mounted: function() {
    this.$refs.input.focus();
  },

  methods: {

    // Function to find gcd of array of numbers
    findGCD: function (arr, n) {
      var result = arr[0];

      // Take the GCD of two pairs
      for (let i = 1; i < n; i++)    {
          result = mathsequences.gcd(arr[i], result);
          if(result == 1)
          {
             return 1;
          }
      }
      return result;

    },

    // Find the lowest common multiple
    findLCM: function (arr, n) {

      var result = arr[0];

      // Take the GCD of two pairs
      for (let i = 1; i < n; i++)    {
          result = mathsequences.lcm(arr[i], result);
          if(result == 1)
          {
             return 1;
          }
      }
      return result;

    },

    // Function called when pressing go
    getGCDandLCM: function() {

      // Reset error flag
      this.error = false;
      console.log(mathsequences);

      // Convert input to array of numbers
      var a = this.input.match(/[0-9]+/g);
      this.gcd = this.findGCD (a, a.length);
      this.lcm = this.findLCM (a, a.length);

      // List prime factors
      this.primes = [];
      for (let i=0; i < a.length; i++) {
        this.primes.push({ n: a[i], primes: mathsequences.primeFactorizationString(parseInt(a[i])) });
      }
    },

  },
}

</script>
