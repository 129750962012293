<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="mb-2">
        Computers don't know letters they only know numbers. These numbers are converted into characters using codepages.
        There are hundreds of codepages. This tool handles quite a few, including the infamous alt-codes.
      </div>
      <div class="form-inline mb-2">
        <label class="form-label mr-2" for="codes">Type of input</label>
        <v-code id="codes" v-model:code="selectedcode"></v-code>
      </div>
      <div class="form-group">
        <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 v-model='message'></textarea>
      </div>
      <div class="form-inline mb-2">
        <input type="button" id="convert" name="convert" value="Convert" class="btn btn-primary mr-2" v-on:click="translateInput">
        <label class="form-label mr-2" for="codes">Requested output</label>
        <v-code id="codes" v-model:code="selectedoutput" @change="translateInput"></v-code>
      </div>
      <div class="card">
        <div class="card-header">Result</div>
        <div class="card-text p-2">{{result}}</div>
      </div>
      <div class="card">
        <div class="card-header">Some information on character codes</div>
        <div class="card-body">
          <div class="card-text mb-2">
            Computers are binary, they only know 0 and 1. These can be converted into a decimal numbers of course. This tool also accepts octal, decimal and hexadecimal
            which are all common among computer programmers. As a bonus the tool converts between these number bases as well. These numbers are used to lookup the right
            character (glyph in nerd speak) in the so-called codepage.
          </div>
          <div class="card-text mb-2">
            The very first computer codepage was ASCII and it has been around ever since. It is a subset of the codepages in MS DOS and Windows and is also a subset of UTF.
            ASCII only had 7-bits (128 characters) available. The codepages from Microsoft used 8 bits (e.g. CP437, CP850, CP1252), 256 characters. In languages with other
            characters different codepages had to be used. With the introduction of UTF-8/16 this changed. With only one codepage there is enough room
            to support all characters on earth, as well as emoji's ;-). <strong>Alt codes</strong>: depending on the version of MS DOS or MS Windows and the installed
            codepage typing the numbers on the numeric keypad with the Alt-key down produced a special character.
          </div>
          <div class="card-text mb-2">
            For a history of all the characters sets used in information systems see <a href="https://en.wikipedia.org/wiki/List_of_information_system_character_sets">
            Wikipedia's history of information system character sets</a>. See <a href="https://en.wikipedia.org/wiki/Code_page">Wikipedia codepages</a> for an overview of
            all the codepages that ever existed.
          </div>
          <div class="card-text mb-2">
            In pre computer days telegraph and telex also used binary representations, which are called the Baudot code (ITA-1) and the
            Murray code (ITA-2). The bits are in the order 54321 normally and in the order 12345 for the reversed codes. There is also a
            Russin version known as MTK-2. More info see <a href="https://en.wikipedia.org/wiki/Baudot_code">Wikipedia</a>.
          </div>
        </div>
      </div>
      <p v-show="error" class="errormsg mt-2">{{errormsg}}</p>
    </div>
  </div>
</template>

<script>
import VCode from '@/components/inputs/VCode.vue'
import * as baudotcode from '@/scripts/baudotcode.js'
import * as codepages from '@/scripts/codepages.js'
import { codePoints, fromCodePoint } from 'utf16-char-codes'

export default {
  name: 'TextCodes',

  props: {
    msg: String,
  },

  data: function () {
    return {
      title: "Characters & Codes",
      message: "",
      result : "Result",
      selectedcode : "Decimal",
      selectedoutput: "UTF16",
      error: false,
      errormsg: "",
    }
  },

  components: {
    VCode,
  },

  mounted: function() {
    this.$refs.message.focus();
  },

  methods: {

    // Decide if input is read one by one or divided by whitespace
    getMany: function (s) {
      switch (s) {
        case "Baudotcode" :
        case "BaudotcodeR" :
        case "Murraycode" :
        case "MurraycodeR" :
        case "MurrayMTK2" :
        case "MurrayMTK2R" :
        case "Binary" :
        case "Octal" :
        case "Decimal" :
        case "Hexadecimal" :
          return true;
        default :
          return false;
      }
    },

    // Convert the intermediate result (a decimal number) to the requested output
    decimalToOutput : function (w) {
      // If input is -1 a control character has been used and output is empty
      if (w == -1) return "";

      // Convert to w to the requested output
      switch (this.selectedoutput) {

        case "Baudotcode" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.baudotASCIIToCode(w);

        case "BaudotcodeR" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.baudotReversedASCIIToCode(w);

        case "Murraycode" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.murrayASCIIToCode(w);

        case "MurraycodeR" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.murrayReversedASCIIToCode(w);

        case "MurrayMTK2" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.murrayMTK2ASCIIToCode(w);

        case "MurrayMTK2R" :
          // Input is decimal value of ASCII character to be converted Baudotcode (could be two)
          return baudotcode.murrayMTK2ReversedASCIIToCode(w);

        case "UTF16" :
          // Input is a decimal number of the UTF character, fromCodePoint comes from the utf-16 lib
          return [fromCodePoint(w)];

        case "Binary" :
          // Input is a decimal number to be converted to binary
          return [w.toString(2)];

        case "Octal" :
          // Input is a decimal number to be converted to octal
          return [w.toString(8)];

        case "Decimal" :
          // Input is a decimal number
          return [w.toString(10)];

        case "Hexadecimal" :
          // Input is a decimal number to be converted to hex
          return [w.toString(16)];

        default :
          // Default find the codepage in codepages.js
          var cp = codepages.findCodepage(this.selectedoutput);
          if (cp >= 0)
            return [codepages.codeToChar(parseInt(w), cp)];
          else {
            // This should never happen
            this.error = true;
            this.errormsg = "Unknown codepage in output";
            return [""];
          }
      }
    },

    // Convert the input to the intermediate result (a decimal number)
    inputToDecimal : function (w) {

      switch (this.selectedcode) {

        case "Baudotcode" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
          return baudotcode.baudotCodeToASCII(w);

        case "BaudotcodeR" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
            return baudotcode.baudotReversedCodeToASCII(w);

        case "Murraycode" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
          return baudotcode.murrayCodeToASCII(w);

        case "MurraycodeR" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
          return baudotcode.murrayReversedCodeToASCII(w);

        case "MurrayMTK2" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
          return baudotcode.murrayMTK2ToASCII(w);

        case "MurrayMTK2R" :
          // Input is a binary string representing the baudotcode
          // Or returns -1 for a control character
          return baudotcode.murrayMTK2ReversedCodeToASCII(w);

        case "UTF16" :
          // input is an UTF character
          // codePoints returns an array of integers, but we always have only one
          return codePoints(w)[0];

        case "Binary" :
          // Input is a binary number convert to decimal
          return parseInt(w, 2);

        case "Octal" :
          // Input is a octal number convert to decimal
          return parseInt(w, 8);

        case "Decimal" :
          // Input is a binary number convert to decimal
          return parseInt(w);

        case "Hexadecimal" :
          // Input is a binary number convert to decimal
          return parseInt(w, 16);

        default :
          // This should never happen
          var cp = codepages.findCodepage(this.selectedcode)
          if (cp >= 0)
            return codepages.charToCode(w, cp);
          else {
            this.error = true;
            this.errormsg = "Unknown codepage in input";
            return -1;
          }
      }
    },

    // Translate the input
    translateInput : function () {

      // Reset error flag and reset baudotcode flag to letters
      this.error = false;
      baudotcode.reset();

      try {
        // Break down input in words
        this.message = this.message.trim();
        var words;
        if (!this.getMany(this.selectedcode)) {
          words = this.message.match(/./gu);
        } else {
          words = this.message.split(/[,.\s]+/g);
        }

        // For each word convert and add to the output
        // Baudotcode and Murraycode might output two strings, so arrays are used
        this.result = "";
        for (let i=0; i < words.length; i++) {
          var inp = this.inputToDecimal(words[i]);
          var outp = this.decimalToOutput(inp);
          for (let i=0; i < outp.length; i++) {
            this.result += outp[i] + " ";
          }
        }
      } catch (e) {
        this.error = true;
        this.errormsg = "Input could not be converted, please check";
        console.log(e);
      }
    },
  },
}
</script>

<style scoped>
</style>
