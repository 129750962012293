<template>
  <div id="nav" class="text-center">
    <router-link to="/coord/coordconvert">Convert (batch)</router-link> |
    <router-link to="/coord/coordproj">Project a point</router-link> |
    <router-link to="/coord/coordmapmaker">Map maker</router-link> |
    <router-link to="/coord/coordincomplete">Incomplete coordinates</router-link> |
    <router-link to="/coord/coordlines">Lines</router-link> |
    <router-link to="/coord/coordtriangles">Triangles</router-link> |
    <router-link to="/coord/coordcircles">Circles</router-link> |
    <router-link to="/coord/coordantipode">Antipode</router-link>
  </div>
  <router-view :key="$route.path" />
  <div v-if="['Coord'].includes($route.name)">
    <div class="container">
      <va-item v-bind:showitem="show[0]" v-on:toggle="toggleItem(0)">
        <template v-slot:header>Coordinates convertor</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordconvert">Converter</router-link></strong> convert list (batches) of coordinates.
          Besides the above formats you can convert to any coordinate system for which you know the proj4js definition.
          Also supports non coordinate systems such as <strong>Maidenhead locator</strong> (QTH) and <strong>Geohashes</strong>.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[1]" v-on:toggle="toggleItem(1)">
        <template v-slot:header>Coordinate projection</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordproj">Project a coordinate</router-link></strong>.
          You can specify the distance in m, km, ft and many more. You can specify the angle in degrees, radians and gon. Internally
          it uses Dutch grid to make the projection (it's easy on an x,y grid), but be aware that deviation gets bigger if you further
          away from the Netherlands.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[6]" v-on:toggle="toggleItem(6)">
        <template v-slot:header>Lines</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordlines">Lines</router-link></strong>.
          Midpoint, distance and direction of a line. Intersection of two lines. Point nearest on a line relative to another point.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[7]" v-on:toggle="toggleItem(7)">
        <template v-slot:header>Triangles</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordtriangles">Triangles</router-link></strong>.
          Centroid, orthocenter, incenter (center of inner circle), circumcenter (center of outer circle) and ninepoints center. Also
          calculates surface area (in square meters) and circumference length (in meters).
        </template>
      </va-item>
      <va-item v-bind:showitem="show[8]" v-on:toggle="toggleItem(8)">
        <template v-slot:header>Circles</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordtriangles">Circles</router-link></strong>.
          Intersection points and area of two circles.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[2]" v-on:toggle="toggleItem(2)">
        <template v-slot:header>Map Maker</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordmapmaker">Map maker</router-link></strong>.
          Specify the coordinates in any format you like and specify the labels. Map Maker will draw the map with markers,
          which you can resize and zoom as you want. This is also the ideal tool to determine possible geocache locations. Give it a list of
          known cache coordinates and check the box draw circles to show all the areas that are not available. You can also
          use this tool to find locations for new geocaches.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[3]" v-on:toggle="toggleItem(3)">
        <template v-slot:header>Incomplete coordinates</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordincomplete">Incomplete coordinates</router-link></strong>.
          Up to 4 variables can be used. The generated coordinates are plotted on the map and assigned a popup with details.
          Maximum number of coordinates that can be plotted is 500.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[4]" v-on:toggle="toggleItem(4)">
        <template v-slot:header>Antipodes</template>
        <template v-slot:content>
          <strong><router-link to="/coord/coordantipode">Antipodes</router-link></strong>.
          Calculate the point exactly on the opposite of the earth of a given coordinate.
        </template>
      </va-item>
      <va-item v-bind:showitem="show[5]" v-on:toggle="toggleItem(5)">
        <template v-slot:header>Supported coordinates</template>
        <template v-slot:content>
          All of these tools support a wide range of coordinate systems: WGS84, UTM, MGRS, Dutch grid (RD), NAD, Belgian Lambert 2008,
          RGF 93 France, Maidenhead locator, Geohash and Open Location Code. The convertor detects the format for WGS84 and NAD
          automatically. It takes N/S/Z and E/O/W, it skips blanks. Read the <router-link :to="{ name: 'Howto', params: { itemnr: '5'}}">Howto
          </router-link> for more information on coordinates.
        </template>
      </va-item>
    </div>
  </div>
</template>

<script>

import VaItem from "@/components/inputs/VaItem.vue"

export default {
  name: 'CoordToolbox',
  props: {
    msg: String
  },

  components: {
    VaItem,
  },

  data: function () {
    return {
      title: "CoordToolbox",
      hidebutton: true,
      show: [true, true, true, true, true, true, true, true, true]
    }
  },

  methods: {

    toggleItem: function(itemnr) {
      this.show[itemnr] = !this.show[itemnr];
    },

  },
}
</script>
