<template>
  <div class="d-flex flex-column mx-4">
    <div class="sectionhead">
      {{title}}
    </div>
    <div class="mainpage">
      <div class="row">
        <div class="col-6">
          <div class="form-row mb-2">
            <label class="form-label col-sm-4 col-md-2 col-lg-1" for="from">From</label>
            <v-datums class="col-sm-8 col-md-6 col-lg-4" id="from" v-model:datum="from"></v-datums>
          </div>
        </div>
        <div class="col-6">
          <div class="form-row mb-2">
            <label class="form-label col-sm-4 col-md-2 col-lg-1" for="to">To</label>
            <v-datums class="col-sm-8 col-md-6 col-lg-4" id="to" v-model:datum="to" @change="convertCoordinates"></v-datums>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <input type="button" id="encode" name="encode" value="Convert" class="btn btn-primary mr-2" v-on:click="convertCoordinates">
        </div>
        <div class="col-6">
          <v-wgsformat id="wgsformat" v-model:format="wgsformat" @change="convertCoordinates"></v-wgsformat>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-6">
          <textarea id="coordfrom" name="coordfrom" class="form-control mt-2" ref="x" placeholder="Coordinates to convert" rows=10 cols=20 v-model='coordfrom'></textarea>
        </div>
        <div class="col-6">
          <textarea id="result" name="result" class="form-control mt-2" ref="x" placeholder="Converted coordinates" rows=10 cols=20 v-model='result'></textarea>
        </div>
        <div class="form-inline mb-2" v-show="to == 'Proj4js' || from == 'Proj4js'">
          <p>You can convert any coordinate system using the definition below. The convertor uses Proj4js. First select Proj4js from the dropdown. The definition to use can be found on <a href='http:\\epsg.io'>EPSG.io</a>. Take the Proj4js.def (it can be found near the bottom of the page). Take the second string (the one that starts with +) without the quotes and copy / paste it in the field below.</p>
          <label class="form-label" for="key1">Proj4js definition</label>
          <input type='text' id="proj4jsdef" name="proj4jsdef" ref="proj4jsdef" v-model="proj4jsdef" size="80" class="form-control ml-2">
        </div>
      </div>
      <div class="errormsg" v-show="error">{{errormsg}}</div>
      <v-map v-model:mylocation="coordfrom"/>
    </div>
  </div>
</template>

<script>
import * as coords from '@/scripts/coords.js';
import VDatums from '@/components/inputs/VDatums.vue';
import VWgsformat from '@/components/inputs/VWgsformat.vue'
import VMap from '@/components/inputs/VMap.vue'

export default {
  name: 'CoordConvert',

  props: {
    msg: String
  },

  data: function() {
    return {
      title: "Convert coordinates",
      error : false,
      errormsg: "",
      coordfrom : "",
      to: "WGS84",
      from: "WGS84",
      result: "",
      proj4jsdef: "",
      count : 0,
      wgsformat : "N52 12.345 E4 12.345"
    }
  },

  components: {
    VDatums,
    VWgsformat,
    VMap,
  },

  methods: {

    // Convert the coordinates
    convertCoordinates: function() {
      // No input
      if (this.coordfrom == null) { this.result = "No coordinates given"; return; }
      if (this.coordfrom == "") { this.result = "No coordinates given"; return; }

      // Get all the lines form input and convert them one by one
      var input = this.coordfrom.match(/[^\r\n]+/g);

      // Clear map of markers
      // this.mymap.eachLayer((layer) => {
      //     layer.remove();
      // });

      // Parse input line by line
      this.result = "";
      this.count = 0;
      this.error = false;
      for (var i = 0; i < input.length; i++) {
        // Split in words
        this.count++;
        try {
          this.result += coords.getTextFromCoord(coords.convertCoordFromText(input[i], this.from, this.to, this.proj4jsdef), this.to, 7, this.wgsformat) + "\n";

          // Add a marker to the map for each coordinaat
          var mapcoord = coords.convertCoordFromText(input[i], this.from, 'WGS84', this.proj4jsdef);
          coords.displayMarker(this.$store.state.L, this.$store.state.mymap, mapcoord, "Point " + this.count);
        } catch(e) {
          this.error = true;
          this.errormsg = "Error converting coordinate, please check the input";
          console.log(e);
        }
      }
    },
  },
}
</script>
