<template>
  <div class="sectionhead">
    <div class="container">
      <div class="text-center">{{cipher}} cipher</div>
    </div>
  </div>
  <div class="mainpage">
    <div class="container">
      <div class="form-inline mb-2">
        <input type="button" id="adfgxv" name="adfgxv" value="ADFGX(V)" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('ADFGVX')">
        <input type="button" id="affine" name="affine" value="Affine" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Affine')">
        <input type="button" id="asmco" name="asmco" value="Amsco" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Amsco')">
        <input type="button" id="atbash" name="atbash" value="Atbash" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Atbash')">
        <input type="button" id="autokey" name="autokey" value="Autokey" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Autokey')">
        <input type="button" id="bacon" name="bacon" value="Bacon" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Bacon')">
        <input type="button" id="base16" name="base16" value="Base16" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Base16')">
        <input type="button" id="base31" name="base31" value="Base31" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('GCId')">
        <input type="button" id="base32" name="base32" value="Base32" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Base32')">
        <input type="button" id="base64" name="base64" value="Base64" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Base64')">
        <input type="button" id="base91" name="base91" value="Base91" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Base91')">
        <input type="button" id="bazeries" name="bazeries" value="Bazeries" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Bazeries')">
        <input type="button" id="beaufort" name="beaufort" value="Beaufort" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Beaufort')">
        <input type="button" id="bibibinary" name="bibibinary" value="Bibi-binary" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Bibibinary')">
        <input type="button" id="bifid" name="bifid" value="Bifid" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Bifid')">
        <input type="button" id="burrowswheeler" name="burrowswheeler" value="Burrows-Wheeler" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Burrowswheeler')">
        <input type="button" id="cadenus" name="cadenus" value="Cadenus" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Cadenus')">
        <input type="button" id="caesar" name="caesar" value="Caesar" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Caesar')">
        <input type="button" id="chaocipher" name="chaocipher" value="Chaocipher" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Chaocipher')">
        <input type="button" id="colloncipher" name="colloncipher" value="Collon" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Collon')">
        <input type="button" id="columnartransposition" name="columnartransposition" value="Columnar Transposition" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Columnartransposition')">
        <input type="button" id="condi" name="condi" value="Condi" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Condi')">
        <input type="button" id="digrafid" name="digrafid" value="Digrafid" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Digrafid')">
        <input type="button" id="foursquare" name="foursquare" value="Foursquare" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Foursquare')">
        <input type="button" id="gcid" name="gcid" value="Geocache ID" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('GCId')">
        <input type="button" id="goldbug" name="goldbug" value="Goldbug" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Goldbug')">
        <input type="button" id="graycode" name="graycode" value="Graycode" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Graycode')">
        <input type="button" id="gromark" name="gromark" value="Gromark" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Gromark')">
        <input type="button" id="gronsfeld" name="gronsfeld" value="Gronsfeld" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Gronsfeld')">
        <input type="button" id="vatsyayana" name="vatsyayana" value="Kamasutra" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Vatsyayana')">
        <input type="button" id="kennycode" name="kennycode" value="Kenny code" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Kennycode')">
        <input type="button" id="monomedinome" name="monomedinome" value="Monome-dinome" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Monomedinome')">
        <input type="button" id="morbit" name="morbit" value="Morbit" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Morbit')">
        <input type="button" id="morse" name="morse" value="Morse" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Morse')">
        <input type="button" id="myszkowski" name="myszkowski" value="Myszkowski" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Myszkowski')">
        <input type="button" id="nicodemus" name="nicodemus" value="Nicodemus" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Nicodemus')">
        <input type="button" id="nihilist" name="nihilist" value="Nihilist" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Nihilist')">
        <input type="button" id="nihilisttransposition" name="nihilisttransposition" value="Nihilist transposition" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Nihilisttransposition')">
        <input type="button" id="onetimepad" name="onetimepad" value="One time pad" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Onetimepad')">
        <input type="button" id="phillips" name="phillips" value="Phillips" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Phillips')">
        <input type="button" id="playfair" name="playfair" value="Playfair" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Playfair')">
        <input type="button" id="pollux" name="pollux" value="Pollux" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Pollux')">
        <input type="button" id="polybius" name="polybius" value="Polybius" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Polybius')">
        <input type="button" id="porta" name="porta" value="Porta" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Porta')">
        <input type="button" id="portax" name="portax" value="Portax" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Portax')">
        <input type="button" id="ragbaby" name="ragbaby" value="Ragbaby" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Ragbaby')">
        <input type="button" id="railfence" name="railfence" value="Railfence" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Railfence')">
        <input type="button" id="redefence" name="redefence" value="Redefence" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Redefence')">
        <input type="button" id="reverse" name="reverse" value="Reverse" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Reverse')">
        <input type="button" id="reversewords" name="reversewords" value="Reverse words" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Reversewords')">
        <input type="button" id="rot5" name="rot5" value="ROT5" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('ROT5')">
        <input type="button" id="rot13" name="rot13" value="ROT13" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('ROT13')">
        <input type="button" id="rot47" name="rot47" value="ROT47" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('ROT47')">
        <input type="button" id="scytale" name="scytale" value="Scytale" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Scytale')">
        <input type="button" id="substitution" name="substitution" value="Substitution" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Substitution')">
        <input type="button" id="trifid" name="trifid" value="Trifid" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Trifid')">
        <input type="button" id="trisquare" name="trisquare" value="Trisquare" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Trisquare')">
        <input type="button" id="uuencode" name="uuencode" value="UUencode" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('UUencode')">
        <input type="button" id="vatsyayana" name="vatsyayana" value="Vatsyayana" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Vatsyayana')">
        <input type="button" id="vigenere" name="vigenere" value="Vigenere" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Vigenere')">
        <input type="button" id="zygazyfa" name="zygazyfa" value="Zygazyfa" class="btn btn-sm btn-primary mb-2 mr-2" v-on:click="changeCipher('Zygazyfa')">
      </div>
      <div id="explanation" class="mb-2">{{explanation}}</div>
      <div class="form-inline mb-2" v-show="showalphabet">
        <label class="form-label" for="alphabet">Alphabet</label>
        <input type='text' id="alphabet" name="alphabet" ref="alphabet" v-model="alphabet" size="52" class="form-control ml-2 mb-2">
        <div v-show="showgenalphabet">
          <input type="button" id="show-modal" name="show-modal" value="Generate square" class="btn btn-primary ml-2 mb-2" v-on:click="showModal = true">
          <v-polybius v-model:polybiussquare="alphabet" v-if="showModal" @close="showModal = false"></v-polybius>
        </div>
      </div>
      <div class="form-inline mb-2" v-show="showkey1">
        <label class="form-label" for="key1">{{labelkey1}}</label>
        <input type='text' id="key1" name="key1" ref="key1" v-model="key1" class="form-control ml-2">
        <div v-show="showgenkey1">
          <input type="button" id="show-modal" name="show-modal" value="Generate square" class="btn btn-primary ml-2" v-on:click="showModal1 = true">
          <v-polybius v-model:polybiussquare="key1" v-if="showModal1" @close="showModal1 = false"></v-polybius>
        </div>
      </div>
      <div class="form-inline mb-2" v-show="shownumkey1">
        <label class="form-label" for="key1">{{labelkey1}}</label>
        <input type='number' id="key1" name="key1" ref="key1" v-model="key1" class="form-control ml-2">
      </div>
      <div class="form-inline mb-2" v-show="showselectkey1">
        <label for="key1" class="form-label mr-2">{{labelkey1}}</label>
        <select id="key1" name="key1" ref="key1" class="custom-select ml-2" v-model="key1">
          <option v-for="v in key1values" :key="v" :value="v.id">{{v.name}} - {{v.id}}</option>
        </select>
      </div>
      <div class="form-inline mb-2" v-show="showkey2">
        <label class="form-label" for="key2">{{labelkey2}}</label>
        <input type='text' id="key2" name="key2" ref="key2" v-model="key2" class="form-control ml-2">
        <div v-show="showgenkey2">
          <input type="button" id="show-modal" name="show-modal" value="Generate square" class="btn btn-primary ml-2" v-on:click="showModal2 = true">
          <v-polybius v-model:polybiussquare="key2" v-if="showModal2" @close="showModal2 = false"></v-polybius>
        </div>
      </div>
      <div class="form-inline mb-2" v-show="shownumkey2">
        <label class="form-label" for="key2">{{labelkey2}}</label>
        <input type='number' id="key2" name="key2" ref="key2" v-model="key2" class="form-control ml-2">
      </div>
      <div class="form-inline mb-2" v-show="showselectkey2">
        <label for="key2" class="form-label mr-2">{{labelkey2}}</label>
        <select id="key2" name="key2" ref="key2" class="custom-select ml-2" v-model="key2">
          <option v-for="v in key2values" :key="v" :value="v.id">{{v.name}} - {{v.id}}</option>
        </select>
      </div>
      <div class="form-inline mb-2" v-show="showkey3">
        <label class="form-label" for="key3">{{labelkey3}}</label>
        <input type='text' id="key3" name="key3" ref="key3" v-model="key3" class="form-control ml-2">
        <div v-show="showgenkey3">
          <input type="button" id="show-modal" name="show-modal" value="Generate square" class="btn btn-primary ml-2" v-on:click="showModal3 = true">
          <v-polybius v-model:polybiussquare="key3" v-if="showModal3" @close="showModal3 = false"></v-polybius>
        </div>
      </div>
      <div class="form-inline mb-2" v-show="shownumkey3">
        <label class="form-label" for="key3">{{labelkey3}}</label>
        <input type='number' id="key3" name="key3" ref="key3" v-model="key3" class="form-control ml-2">
      </div>
      <div class="row">
        <div class="col-6">
          <input type="button" id="encode" name="encode" value="Encode" class="btn btn-primary mb-2 mr-2" :disabled="btnEncodeDisable" v-on:click="toEncode">
          <div class="form-group">
            <textarea id="message" name="message" class="form-control" ref="message" placeholder="Message" rows=10 cols=50 v-model='message'></textarea>
          </div>
          <input type="button" id="uppercase" name="uppercase" value="to Uppercase" class="btn btn-primary mb-2 mr-2" v-on:click="convertUpper">
          <input type="button" id="remove" name="remove" value="Remove non alpha" class="btn btn-primary mb-2 mr-2" v-on:click="removeUnknown">
          <div class="form-inline mb-2" v-show="showreplacements">
            <input type="button" id="replace" name="replace" value="Replace" class="btn btn-primary" v-on:click="replaceCharacters">
            <input type='text' id="replacements" name="replacements" ref="replacements" placeholder="J=I, U=V" v-model="replacements" size="12" class="form-control ml-2">
            <input type="button" id="analyze" name="analyze" value="Replace diacritics" class="btn btn-primary ml-2" v-on:click="removeDiacr">
          </div>
        </div>
        <div class="col-6">
          <input type="button" id="decode" name="decode" value="Decode" class="btn btn-primary mb-2 mr-2" :disabled="btnDecodeDisable" @click="toDecode">
          <div class="form-group">
            <textarea id="translatedmessage" name="translatedmessage" class="form-control" ref="translatedmessage" placeholder="Translation" cols=50 rows=10 v-model='translatedmessage'></textarea>
          </div>
          <div class="form-inline">
            <input type="button" id="format" name="format" value="Format" class="btn btn-primary" v-on:click="formatMessage">
            <label class="form-label ml-2" for="separator">Separator</label>
            <select id="separator" name="separator" ref="separator" v-model="separator" class="custom-select ml-2">
              <option> </option>
              <option>-</option>
              <option>/</option>
            </select>
            <label class="form-label ml-2" for="blocksize">Block size</label>
            <input type='number' id="blocksize" name="blocksize" ref="blocksize" v-model="blocksize" min="1" max="9" class="form-control ml-2">
            <input type="button" id="unformat" name="unformat" value="Remove format" class="btn btn-primary ml-2" v-on:click="unformatMessage">
          </div>
        </div>
      </div>
      <p v-show="error" class="errormsg">{{errormsg}}</p>
    </div>
  </div>
</template>

<script>
import * as textHelper from '@/scripts/texthelper.js';
import VPolybius from '@/components/inputs/VPolybius.vue'

export default {
  name: 'Encoderdecoder',

  // Prop initcipher is parameter passed by route (optional)
  props: {
    msg: String,
    initcipher: String,
  },

  // Data include variables to dynamically set appearnce and labels
  data: function () {
    return {
      showModal: false,
      showModal1: false,
      showModal2: false,
      showModal3: false,
      square1: "Square1",
      title: "Encode or decode",
      message: "",
      translatedmessage: "",
      btnDecodeDisable: false,
      btnEncodeDisable: false,
      error: false,
      errormsg: "",
      separator: ' ',
      blocksize: 5,
      cipher: '',
      showalphabet: true,
      alphabet: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
      showgenalphabet: false,
      removeunknown: false,
      showreplacements: true,
      replacements: '',
      shownumkey1: false,
      showselectkey1 : false,
      key1values: [],             // Object array with id and name
      showkey1: false,
      labelkey1: '',
      key1: '',
      showgenkey1: false,
      shownumkey2: false,
      showselectkey2 : false,
      key2values: [],             // Object array with id and name
      showkey2: false,
      labelkey2: '',
      key2: '',
      showgenkey2: false,
      shownumkey3: false,
      showkey3: false,
      labelkey3: '',
      key3: '',
      showgenkey3: false,
      explanation: 'Some info on this cipher',
      phpurl: window.location.protocol + "//"  + window.location.hostname + "/cipher-toolkit/encoderdecoder.php",
    }
  },

  components: {
    VPolybius,
  },

  mounted: function() {
    //this.$refs.message.focus();
    if (typeof(this.$route.params.cipher) != "undefined")
      this.changeCipher(this.$route.params.cipher);
    else
      this.changeCipher('Atbash');
  },

  methods: {

    // Ruthless replacement of diacritics
    removeDiacr: function() {
      this.message = textHelper.removeDiacritics(this.message);
    },

    // Decode transmsg
    toDecode: function() {
      this.error = false;
      let data = {
        alphabet: this.alphabet,
        removeunknown: this.removeunknown,
        replacements: this.replacements,
        cipher: this.cipher,
        enordecode: 'decode',
        message: this.message,
        transmessage: this.translatedmessage,
        key1: this.key1,
        key2: this.key2,
        key3: this.key3
      };

      // Call PHP script on server
      fetch(this.phpurl, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            this.message = data.message;
            // console.log('Success', data)
        })
        .catch((error) => {
            console.error('Error ', error);
            this.errormsg = "Message could not be decoded, please check."
            this.error = true;
        });
    },

    // Encode message
    toEncode: function() {
      this.error = false;
      let data = {
          alphabet: this.alphabet,
          removeunknown: this.removeunknown,
          replacements: this.replacements,
          cipher: this.cipher,
          enordecode: 'encode',
          message: this.message,
          transmessage: this.translatedmessage,
          key1: this.key1,
          key2: this.key2,
          key3: this.key3
      };

      // Call PHP script on server
      fetch(this.phpurl, {
          method: 'POST',
          body: JSON.stringify(data)
        })
        .then(response => response.json())
        .then(data => {
            this.translatedmessage = data.transmessage;
            // console.log('Success', data)
        })
        .catch((error) => {
            console.error('Error ', error);
            this.errormsg = "Message could not be decoded, please check."
            this.error = true;
        });
    },

    // Change the cipher, reset defaults and set required inputs
    changeCipher: function(newcipher) {
      this.cipher = newcipher;
      this.showalphabet = true;
      this.showModal  = false;
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      this.showgenalphabet = false;
      this.showreplacements = true;
      this.key1 = "";
      this.showkey1 = false;
      this.shownumkey1 = false;
      this.showgenkey1 = false;
      this.key2 = "";
      this.showkey2 = false;
      this.shownumkey2 = false;
      this.showselectkey2 = false;
      this.showgenkey2 = false;
      this.key3 = "";
      this.showkey3 = false;
      this.shownumkey3 = false;
      this.showgenkey3 = false;
      this.explanation = "No info on this cipher";
      switch (this.cipher) {
        case 'ADFGVX':
          this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          this.showgenalphabet = true;
          this.showkey1 = true;
          this.labelkey1 = "Column transposition key:";
          this.explanation = 'The ADFGX(V) cipher is Polybius cipher. A 5x5 or 6x6 square is used. The result is fractionated and the columns are transposed using the key.'
          break;
        case 'Affine':
          this.shownumkey1 = true;
          this.key1 = 5;
          this.labelkey1 = "A (greater than 1):";
          this.shownumkey2 = true;
          this.key2 = 5;
          this.labelkey2 = "B (greater than 0):";
          this.explanation = 'The Affine cipher is a substitution cipher. It uses the function ax + b to calculate the ciphered text. The value of A must be coprime with the length of the alphabet.'
          break;
        case 'Amsco':
          this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
          this.showkey1 = true;
          this.labelkey1 = "Column transposition key:";
          this.explanation = 'AMSCO Encryption consists in writing a text in a grid according to a cutting sequence (2,1) then use a permutation key to switch columns.'
          break;
        case 'Atbash':
          this.explanation = 'Atbash is a very simpel cipher where each letter is replaced with the one in the reversed alphabet, so A=Z, B=Y ...'
          break;
        case 'Autokey' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key:";
          this.explanation = "An autokey cipher (also known as the autoclave cipher) is a cipher that incorporates the message (the plaintext) into the key. This one uses Vigenere.";
          break;
        case 'Bacon' :
          this.shownumkey1 = true;
          this.key1 = 1;
          this.labelkey1 = "Version (1 or 2)";
          this.showkey2 = true;
          this.labelkey2 = "Value to use for A:";
          this.key2= 'A';
          this.showkey3 = true;
          this.labelkey3 = "Value to use for B:";
          this.key3= 'B';
          this.explanation = "Each character is replaced by 5 characters (normally A and B). There are two versions. Versions 1 uses the same combination for I and J, and U and V. Version 2 is different for each letter. You can also hide Bacon in any text using a different font for B or using lower- and uppercase for A and B.";
          break;
        case 'Base91':
        case 'Base64':
        case 'Base32':
        case 'Base16':
        case 'UUencode' :
          this.showalphabet = false;
          this.explanation = 'Base 91, Base 16, Base 32, Base 64 and UUEncode are all methods for encoding binary data as ASCII.'
          break;
        case 'Bazeries' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Key for square 1:";
          this.showgenkey1 = true;
          this.showkey2 = true;
          this.labelkey2 = "Key for square 2:";
          this.showgenkey2 = true;
          this.showkey3 = true;
          this.labelkey3 = "Numeric key:";
          this.explanation = "The Bazeries cipher is a polygraphic cipher coding 2-letters at a time. The alphabet must be a square number characters. It uses two squares. The keys are optional to generate the squares. The numeric key is used for transpositioning and to generate the 2nd square if key 2 is empty."
          break;
        case 'Beaufort' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key:";
          this.explanation = "The Beaufort cipher, created by Sir Francis Beaufort, is a substitution cipher similar to the Vigenère cipher, with a slightly modified enciphering mechanism and tableau. Keyword is required.";
          break;
        case 'Bibibinary' :
          this.alphabet = "0123456789";
          this.explanation = "Bibi-binary is a numeric encoding system. It converts the number to hexadecimal and translates into something that pronounces funny. Only numbers are allowed."
          break;
        case 'Bifid' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showgenalphabet = true;
          this.showkey1 = true;
          this.key1 = '';
          this.labelkey1 = "Enter key:";
          this.explanation = "The bifid cipher uses a Polybius square to fractionate each plaintext letter into a bigram, mixes the constituents of the bigrams, and then applies the Polybius square in reverse to turn these mixed bigrams into ciphertext letters.";
          break;
        case 'Burrowswheeler' :
          this.showkey1 = true;
          this.key1 = '#';
          this.labelkey1 = "End of file character:";
          this.explanation = "The Burrow Wheeler cipher is a transposition cipher. It uses a character to indicate end-of-file. This on is mandatory"
          break;
        case 'Cadenus' :
          this.showkey1 = true;
          this.key1 = '';
          this.labelkey1 = "Enter columnar transposition key:";
          this.explanation = "The Cadenus cipher is a columnar transposition. The key both reorders columns and sets the starting point of each column. The message must be a 25 * keylength characters."
          break;
        case 'Caesar' :
        case 'ROT13' :
          this.shownumkey1 = true;
          this.key1 = 13;
          this.labelkey1 = "Shift:";
          this.explanation = "The Caesar cipher is a classic substitution cipher. Each letter is replaced with a letter a number of positions to the right. ROT13 is the same as a Caesar cipher with a shift of 13."
          break;
        case 'Chaocipher' :
          this.showkey1 = true;
          this.key1 = 'ABCDEFMNOPQRSGHIJKLTUVWXYZ';
          this.labelkey1 = "Left starting alphabet:";
          this.showkey2 = true;
          this.key2 = 'PQRSTUVWXYZABCDEKLMNOFGHIJ';
          this.labelkey2 = "Right starting alphabet:";
          this.explanation = "The chaocipher is polyalphabetic cipher with continuously changing alphabets. It was invented in 1918 by John F. Byrne."
          break;
        case 'Collon' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showselectkey1 = true;
          this.labelkey1 = "Select order of digrams:"
          this.key1values = [
            { id: "RFCL", name: "Row first, column last"},
            { id: "RLCL", name: "Row last, column last"},
            { id: "RFCF", name: "Row first, column first"},
            { id: "RLCF", name: "Row last, column first"},
            { id: "CLRL", name: "Column last, row last"},
            { id: "CLRF", name: "Column last, row first"},
            { id: "CFRF", name: "Column first, row first"},
            { id: "CFRL", name: "Column first, row last"},
          ];
          this.key1 = "RFCL";
          this.showkey2 = true;
          this.labelkey2 = "Key to generate Polybius squares:";
          this.shownumkey3 = true;
          this.labelkey3 = "Period:";
          this.key3 = 3;
          this.explanation = "Collon uses a grid and convert letters into bigram. The bigram is taken from the first/last row/column in the grid. The period is then used for fractionation."
          break;
        case 'Columnartransposition' :
          this.showkey1 = true;
          this.labelkey1 = "Column key:";
          this.explanation = "Columnar transposition writes the message in columns, reorders it and writes the result column after column";
          break;
        case 'Condi' :
          this.showkey1 = true;
          this.labelkey1 = "Key for the cipher alphabet:";
          this.shownumkey2 = true;
          this.key2 = 0;
          this.labelkey2 = "Initial offset:";
          this.explanation = "The condi cipher (consecutive digrams) uses digrams to calculate the offset for the next letter."
          break;
        case 'Digrafid' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ#";
          this.showkey1 = true;
          this.labelkey1 = "Key for the first grid:";
          this.showkey2 = true;
          this.labelkey2 = "Key for the second grid:";
          this.shownumkey3 = true;
          this.labelkey3 = "Period:";
          this.explanation = " A fractionated cipher using a tableau in which two alphabets are organized in grids (of 3x9 and 9x3) with 27 characters (ABCDEFGHIJKLMNOPQRSTUVWXYZ#) are mixed. The plaintext is divided into digraphs, and the digraphs are written in groups, the number of digraphs in each group being the period of the cipher";
          break;
        case 'Foursquare':
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.key1 = "";
          this.labelkey1 = "Key square 1";
          this.showgenkey1 = true;
          this.showkey2 = true;
          this.key2 = "";
          this.labelkey2 = "Key square 2";
          this.showgenkey2 = true;
          this.explanation = 'The foursquare cipher is a polygraphic substitution cipher that replaces diagrams using two Polybius squares';
          break;
        case 'GCId' :
          this.showalphabet = false;
          this.explanation = 'Geocache id, a.k.a base 31, translates a geocache id (a number) to its geocache code like GC001. You can enter multiple geoaches or ids.'
          break;
        case 'Goldbug':
          this.explanation = 'The Gold-Bug cipher was included in a short story by Edgar Allan Poe and which was published in 1843. It is a simple substitution cipher.'
          break;
        case 'Graycode':
          this.shownumkey1 = true;
          this.key1 = 4;
          this.labelkey1 = "Number of digits";
          this.alphabet = "0123456789";
          this.explanation = 'The reflected binary code (RBC), also known just as reflected binary (RB) or Gray code after Frank Gray, is an ordering of the binary numeral system such that two successive values differ in only one bit (binary digit).'
          break;
        case 'Gromark':
          this.showkey1 = true;
          this.labelkey1 = "Key";
          this.showkey2 = true;
          this.key2 = "";
          this.labelkey2 = "Primer (5 digits)";
          this.explanation = 'The gromark uses the key to reorder the alphabet and then uses the same key for columnar transposition of the cipher. The 5-digit primer is used to determine the shift of each letter.'
          break;
        case 'Gronsfeld' :
          this.showkey1 = true;
          this.key1 = '';
          this.labelkey1 = "Enter key (numeric):";
          this.explanation = "The Gronsfeld cipher a Vigenere cipher using a numeric key.";
          break;
        case 'Kennycode':
          this.explanation = 'Kenny code is based on the mumbling of the Southpark character. Each letter is encoded with a combination of m, p and f (fff is not used).'
          break;
        case 'Monomedinome' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXZ";
          this.showkey1 = true;
          this.labelkey1 = "Key to reorder the alphabet:";
          this.showkey2 = true;
          this.labelkey2 = "Key for transposition (min 10 chars):";
          this.explanation = "The Monome-dinome cipher uses a 24 char alphabet. The first key is used to generate a 3x8 square. The second key is used to detemine the numbers for the rows and columns."
          break;
        case 'Morbit' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key (9 letters):";
          this.explanation = "Morbit first translates the message into Morse. Then the morse code is split into couples of 2 characters, which are translated into numbers using a 9-digit key";
          break;
        case 'Morse' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXZ0123456789.,?!-/:'();=@&" + '"';
          this.explanation = "Morse code is well known. It uses short and long beeps (. and -) to transmit messages. This one also does morse for numbers and .,?!-/:'();=@&" + '".';
          break;
        case 'Myszkowski' :
          this.showkey1 = true;
          this.labelkey1 = "Key for columnar transposition (should contain double letters):";
          this.explanation = "The Myszkowski cipher is a columnar transposition that uses a key with double letters. Plaintext columns with unique letters are transcribed downward; those with recurring letters are first transcribed left to right:"
          break;
        case 'Nicodemus' :
          this.showkey1 = true;
          this.labelkey1 = "Key for columnar transposition and Vigenere:";
          this.explanation = "The Nicodemus cipher first uses columnar transposition with the given key. The message is then encoded with Vigenere using the same key. The result is written column by column in blocks of 5 letters."
          break;
        case 'Nihilist' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Key to create Polybius square:";
          this.showgenkey1 = true;
          this.showkey2 = true;
          this.key2 = '';
          this.labelkey2 = "Key to be added:";
          this.explanation = "The Nihilist cipher is a Polybius cipher. The first key is used to generate a 5x5 square. The second key is added to the generated numbers. The alphabet must be 25 characters and both keys are required."
          break;
        case 'Nihilisttransposition' :
          this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Key for columnar transposition:";
          this.showselectkey2 = true;
          this.key2 = true;
          this.key2values = [
            { id: true, name: "Rows"},
            { id: false, name: "Columns"},
          ];
          this.labelkey2 = "Read by rows or columns";
          this.explanation = "The Nihilist transposition cipher is a columnar followed by a row based transposition, using the same key for both. The transposed grid can be read by rows or columns. The message size equals square of the key length."
          break;
        case 'Onetimepad' :
          this.showkey1 = true;
          this.labelkey1 = "Enter pad";
          this.explanation = "The one time pad has a key with the same length as the message. It is the only truly unbreakable cipher (if you never reuse the pad)."
          break;
        case 'Phillips' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Enter key";
          this.showgenkey1 = true;
          this.explanation = "The Phillips cipher is based on a Polybius square whose rows, columns or both are reordered to form 8 different squares. The plaintext is enciphered using each square in turn for groups of 5 letters."
          break;
        case 'Playfair' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Enter key:";
          this.showgenkey1 = true;
          this.explanation = "The Playfair cipher is a polygraphic cipher coding 2-letters at a time. The alphabet must be a square number characters. The key is optional and used to generate the square. "
          break;
        case 'Polybius' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showgenalphabet = true;
          this.showkey1 = true;
          this.key1 = '12345';
          this.labelkey1 = "Rows: ";
          this.showkey2 = true;
          this.key2 = '12345';
          this.labelkey2 = "Columns:";
          this.explanation = "The Polybius cipher uses a 5x5 (or 6x6) square. The rows and columns are normally numbered 1 to 5 (or 1 to 6) but this can be changed. The alphabet must be 25 or 36 characters and both keys are required."
          break;
        case 'Pollux' :
          this.showkey1 = true;
          this.key1 = '.';
          this.labelkey1 = "Characters for .:";
          this.showkey2 = true;
          this.key2 = '-';
          this.labelkey2 = "Characters for -:";
          this.key3 = 'x';
          this.showkey3 = true;
          this.labelkey3 = "Characters for x:"
          this.explanation = "The Pollux cipher translates a message into morse and then replaces the dot, hyphen and word breaks with a randomly selected character."
          break;
        case 'Porta' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key:";
          this.explanation = "The porta cipher is a polyalphabetic cipher which uses 13 alphabets."
          break;
        case 'Portax' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key:";
          this.explanation = "Portax is a polygraphic (replaces digrams), polyalphabetic (it shifts the alphabet), transposition (rows to columns) cipher."
          break;
        case 'Ragbaby' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key";
          this.explanation = "Using a selected keyword, construct a mixed alphabet. Keeping word divisions, the letters of the plaintext are numbered sequentially beginning with the first character of each word.  Ciphertext characters are obtained by taking the letter that is the indicated number of places to the right."
          break;
        case 'Railfence':
          this.shownumkey1 = true;
          this.key1 = 3;
          this.labelkey1 = "Number of rails (greater than 1):";
          this.shownumkey2 = true;
          this.key2 = 0;
          this.labelkey2 = "Initial offset:";
          this.explanation = 'The railfence cipher is a transposition cipher.'
          break;
        case 'Redefence':
          this.shownumkey1 = true;
          this.key1 = 3;
          this.labelkey1 = "Number of rails (greater than 1):";
          this.shownumkey2 = true;
          this.key2 = 0;
          this.labelkey2 = "Initial offset:";
          this.showkey3 = true;
          this.labelkey3 = "Sequence of rails:"
          this.explanation = 'The redefence cipher is a transposition cipher. It is the generic form of the railfence cipher.'
          break;
        case 'Reverse' :
          this.showalphabet = false;
          this.explanation = 'Reverse the message';
          break;
        case 'Reversewords' :
          this.showalphabet = false;
          this.explanation = 'Reverse each word of the message';
          break;
        case 'ROT5' :
          this.alphabet = "0123456789";
          this.shownumkey1 = true;
          this.labelkey1 = "Shift:";
          this.key1 = 5;
          this.explanation = "ROT9 is a simple Caesar cipher that just uses digits. Default it has a shift of 5";
          break;
        case 'ROT47' :
          this.alphabet = "!\"#$%&'()*+,-./0123456789:;<=>?@ABCDEFGHIJKLMNOPQRSTUVWXYZ[\\]^_`abcdefghijklmnopqrstuvwxyz{|}~";
          this.shownumkey1 = true;
          this.labelkey1 = "Shift:";
          this.key1 = 47;
          this.explanation = "ROT47 is a Caesar cipher that uses an alphabet with lower- and uppercase, digits and some symbols (ASCII karakters 33 t/m 126). Default it has a shift of 47";
          break;
        case 'Scytale' :
          this.shownumkey1 = true;
          this.key1 = 3;
          this.labelkey1 = "Number of turns:";
          this.explanation = "Scytale is a very basique transposition cipher used in ancient Greece and by spartans. It uses a cylinder and a band characterized by its number of turns around the cylinder."
          break;
        case 'Substitution' :
          this.showkey1 = true;
          this.labelkey1 = "Substitutes:";
          this.explanation = "The generic substitution uses a random reordered alphabet. If you don't know it you can better use the Smart Replace tool.";
          break;
        case 'Trifid' :
          this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ#";
          this.showkey1 = true;
          this.labelkey1 = "Enter key";
          this.explanation = "The trifid cipher uses a table to fractionate each plaintext letter into a trigram, mixes the constituents of the trigrams, and then applies the table in reverse to turn these mixed trigrams into ciphertext letters. The alphabet must be 27 long."
          break;
        case 'Trisquare' :
          this.alphabet = "ABCDEFGHIKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.labelkey1 = "Enter key square 1";
          this.showgenkey1 = true;
          this.showkey2 = true;
          this.labelkey2 = "Enter key square 2";
          this.showgenkey2 = true;
          this.showkey3 = true;
          this.labelkey3 = "Enter key square 3";
          this.showgenkey3 = true;
          this.explanation = "The trisquare cipher uses three polybius squares and encodes digrams (2 letters) into trigrams (3 letters).";
          break;
        case 'Vatsyayana' :
          this.alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
          this.showkey1 = true;
          this.key1 = 'ABCDEFGHIJKLM';
          this.labelkey1 = "First half:";
          this.showkey2 = true;
          this.key2 = 'NOPQRSTUVWXYZ';
          this.labelkey2 = "Second half";
          this.explanation = "The Vatsyayana or Kamasutra cipher uses pairs of the letters who replace each other."
          break;
        case 'Vigenere' :
          this.showkey1 = true;
          this.labelkey1 = "Enter key";
          this.explanation = "The Vigenère cipher is a method of encrypting alphabetic text by using a series of interwoven Caesar ciphers, based on the letters of a (required) keyword. It employs a form of polyalphabetic substitution."
          break;
        case 'Zygazyfa' :
          this.showkey1 = true;
          this.key1 = 'KEY';
          this.labelkey1 = "Enter key:";
          this.explanation = "The Zygazyfa cipher was created by Ray Crowther for fun. He uses it in some of his geocaches. It is a polyalphabetic cipher using a kind of autokey."
          break;
        default :
          this.error = true;
          this.errormsg = "Something went wrong unknown cipher" + this.cipher;
          console.log('Unknown cipher' + this.cipher);
      }
    },

    // Convert message to uppercase
    convertUpper: function() {
      this.message = this.message.toUpperCase();
    },

    // Remove characters not present in alphabet from message
    removeUnknown: function() {
      var s = "";
      for (var i=0; i < this.message.length; i++)
        if (this.alphabet.indexOf(this.message[i]) >= 0) s += this.message[i];
      this.message = s;
    },

    // Add separators to transmsg
    formatMessage: function() {
      var s = "";
      for (var i = 0; i < this.translatedmessage.length; i++) {
        if ((i % this.blocksize == 0) && (i>0)) s += this.separator;
          s += this.translatedmessage[i];
      }
      if (s[s.length-1] == this.separator) s = s.slice(0, s.length-2);
      this.translatedmessage = s;
    },

    // Remove separators from transmsg
    unformatMessage: function() {
      var s = "";
      var block = parseInt(this.blocksize);
      for (var i = 0; i < this.translatedmessage.length; i++)
			if ((i+1) % (block+1) != 0) s += this.translatedmessage[i];
      this.translatedmessage = s;
    },

    // Replace characters in message
    replaceCharacters: function () {
      var arr = this.replacements.match(/[A-Za-z]=[A-Za-z]/g);
      if (arr !== null) for (var i=0; i<arr.length; i++) {
        var s = "";
        for (var j=0; j<this.message.length; j++)
          if (this.message[j] === arr[i][0])
            s += arr[i][2];
          else
            s += this.message[j];
        this.message = s;
      }

    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<!--   background-image: url('headerbackground.png'); -->

<style scoped>

#explanation {
  font-size: 1.2em;
}

</style>
